import { langConfig } from '../../config/langConfig';
const htmllang = document.querySelector('html').getAttribute('lang') || '';
const language = langConfig[htmllang].lang || '';
const country_code = langConfig[htmllang].countrycode || '';
const country_code_upper = country_code.toUpperCase();
const handleCount = (data, basket, basketCount) => {
    const clickmarker = `${langConfig[htmllang].markerprefix}/topnavi/warenkorb`;
    if(data){
        if (data.count !== 0) {
            basketCount.setAttribute('data-basket-count', data.count);
            basket.setAttribute('data-emos-target', `["Topnavi ${country_code_upper}", "Warenkorb Icon", 1, "s"]`);
            basket.setAttribute('data-emos-clickmarker', clickmarker);
        } else {
            basket.setAttribute('data-emos-target', `["Topnavi ${country_code_upper}", "Warenkorb leer", 1, "s"]`);
            basket.setAttribute('data-emos-clickmarker', clickmarker + '/keinProdukt');
            basketCount.removeAttribute('data-basket-count');
        }
    } else {
        basket.removeAttribute('data-emos-target');
        basket.removeAttribute('data-emos-clickmarker');
        basketCount.removeAttribute('data-basket-count');
    }
};
const goToBasket = (language) => {
    setTimeout(() => { // short timeout to make sure tracking is send
        window.location.href = `/buy/${language.toLowerCase()}/basket/display`;
    }, 500);
};
const transformToJSON = (response) => {
    if(response.ok){
        return response.json();
    }
};

export const showProducts = () => {
    const basketCount = document.getElementById('basket_count');
    const basket = document.getElementById('basket');
    // check if basket is available if not return
    if(!basketCount || !basket){
        return;
    }
    return fetch(`/buy/${language}/basket/count`)
        .then(transformToJSON)
        .then(data => {
            handleCount(data, basket, basketCount);
            basket.addEventListener('click', () => {
                goToBasket(language);
            });
        });
};
