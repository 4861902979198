/* eslint-disable no-unused-vars */
//@ts-check
import SELECT from '../dom/selector-engine';
import Swiper, {Pagination} from 'swiper';

const flipCards = SELECT.find('.flip-card');

const toggleFlipCard = card => {

    const frontCard = card.currentTarget.querySelector('.flip-card__face--front') || null;
    const backCard = card.currentTarget.querySelector('.flip-card__face--back') || null;
    // check if elements are available
    if (!(backCard && frontCard)) {
        return;
    }
    if(cardIsNotFlipped(card)) {
        setSameDimensions(backCard, frontCard);
    }
    toggleClasses(backCard, frontCard, card);
};

const toggleClasses = (backCard, frontCard, card) => {
    // check if elements are available
    if (!(card && backCard && frontCard)) {
        return;
    }
    // toggle classes
    card.currentTarget.classList.toggle('is--flipped');
    backCard.classList.toggle('d-none');
    frontCard.classList.toggle('d-none');
};

const setSameDimensions = (backCard, frontCard) => {
    // check if elements are available
    if (!(backCard && frontCard)) {
        return;
    }
    const frontCardHeight = frontCard.offsetHeight;
    const frontCardWidth = frontCard.offsetWidth;
    backCard.style.height = frontCardHeight + 'px';
    backCard.style.width = frontCardWidth + 'px';
};

const cardIsNotFlipped = card => {
    return !card.currentTarget.classList.contains('is--flipped');
};

//add event listener to all flip cards
flipCards.forEach((element) => {
    element.addEventListener('click', toggleFlipCard);
});

/* eslint-disable max-lines-per-function */
/// Swiper
const flipcardSlider = () => {
    const sliderFlipcard = document.querySelectorAll('.swiper.flipcard');

    const sliderFlipcardWrapper = document.querySelectorAll('.flipcard_wrapper');

    const flipcardCounter = document.querySelectorAll('.flipcard-count');
    let p = 0;
    for (p = 0; p < flipcardCounter.length; p++) {
        // needed to show tooltips over next pricecard. otherwise the tooltips can be hidden by next pricecard.
        flipcardCounter[p].style['z-index'] = flipcardCounter.length - p;
    }

    let i = 0;
    for (i = 0; i < sliderFlipcard.length; i++) {
        sliderFlipcard[i].classList.add('flipcard-'+ i);
        let flipSlider = new Swiper('.flipcard-' + i, {
            modules: [Pagination],
            slidesPerView: 'auto',
            loop: false,
            centeredSlides: true,
            grabCursor: true,
            pagination: {
                el: '.swiper-pagination_flipcard',
                clickable: true
            },
            // breakpoints used to be here *
            on: {
                init: function() {
                    // fade in slider after highlighted pricecard is focused
                    setTimeout(() => {
                        // check if class exists
                        sliderFlipcardWrapper.forEach(element => {
                            element.classList.add('fadeIn');
                        });
                    }, 5);
                },
            },
        });
    }

};
document.readyState === 'complete' ? flipcardSlider() : window.addEventListener('load', flipcardSlider);


// * Breakpoints entfernt, da kein Effekt vorhanden. Sicherheitshalber für Testphase hier auskommentiert hinterlassen.
// breakpoints: {
//     400: {
//         slidesPerView: 'auto',
//         spaceBetween: 24,
//     },
//     580: {
//         slidesPerView: 'auto',
//         spaceBetween: 24,
//     },
//     640: {
//         slidesPerView: 'auto',
//         spaceBetween: 24,
//     },
//     768: {
//         slidesPerView: 'auto',
//         spaceBetween: 0,
//         grabCursor: false,
//         allowTouchMove: true,
//         loop: false,
//     },
// },