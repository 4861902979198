/**
 * Copies the code inside a codebox to the clipboard and updates the UI
 * @param {Number} codeBoxIndex The index of the codebox element to copy.
 */
function copyCode(codeBoxIndex){
    let copyArea = document.querySelector(`.copy-area[data-codebox-index="${codeBoxIndex}"]`);
    let copyButton = document.querySelector(`.libraryCopyButton[data-codebox-index="${codeBoxIndex}"]`);
    navigator.clipboard.writeText(unescapeHtml(copyArea.innerHTML)).then(() => {
        copyButton.innerHTML = 'copied';
        copyButton.style.backgroundColor = 'var(--success)';
        setTimeout(() => {
            copyButton.innerHTML = 'Copy Code';
            copyButton.style.backgroundColor = 'var(--primary)';
        }, 400);
    },() => {
        copyButton.innerHTML = 'failed';
        copyButton.style.backgroundColor = 'var(--warning)';
        setTimeout(() => {
            copyButton.innerHTML = 'Copy Code';
            copyButton.style.backgroundColor = 'var(--primary)';
        }, 400);
    });
}

/**
 * Add the codeboxIndex data property to all codebox element
 *
 */
var allCodeBoxes = document.querySelectorAll('.copy-area');
for (let index = 0; index < allCodeBoxes.length; index++) {
    allCodeBoxes[index].dataset.codeboxIndex = index + 1;
    allCodeBoxes[index].innerHTML = escapeHtml(allCodeBoxes[index].innerHTML);
}

/**
 * Add the codeboxIndex data property to all code-container element
 *
 */
var allCodeContainer = document.querySelectorAll('.code-container--copy-code');
for (let index = 0; index < allCodeContainer.length; index++) {
    allCodeContainer[index].dataset.codeboxIndex = index + 1;
}

/**
 * Show or hide the code container and handle the HTML of button to show/hide
 * @param {Number} codeBoxIndex The index of the codebox element to show/hide.
 */
function showCode(codeBoxIndex){
    let showButton = document.querySelector(`.libraryShowButton[data-codebox-index="${codeBoxIndex}"]`);
    let codeContainer = document.querySelector(`.code-container--copy-code[data-codebox-index="${codeBoxIndex}"]`);
    codeContainer.style.display = codeContainer.style.display === 'none' ? 'block' : 'none';
    showButton.innerHTML = codeContainer.style.display === 'none' ? 'Show Code' : 'Hide Code';
}

/**
 * Add event listener on show buttons to trigger the showCode function
 *
 */
let showButton = document.querySelectorAll('.libraryShowButton');
for (let index = 0; index < showButton.length; index++) {
    showButton[index].dataset.codeboxIndex = index + 1 ;
    showButton[index].addEventListener('click', () => {
        showCode(showButton[index].dataset.codeboxIndex);
    });
}

/**
 * Add event listener on copy buttons to trigger the copyCode function
 *
 */
let copyButton = document.querySelectorAll('.libraryCopyButton');
for (let index = 0; index < copyButton.length; index++) {
    copyButton[index].dataset.codeboxIndex = index + 1 ;
    copyButton[index].addEventListener('click', () => {
        copyCode(copyButton[index].dataset.codeboxIndex);
    });
}

/**
 * Escape HTML special characters from a given string
 *
 * @param {String} code The string that you want to escape
 * @returns {String} The escaped string
 */
function escapeHtml(code) {
    return code
        .replaceAll(/&/g, '&amp;')
        .replaceAll(/</g, '&lt;')
        .replaceAll(/>/g, '&gt;')
        .replaceAll(/"/g, '&quot;')
        .replaceAll(/'/g, '&#039;');
}

/* eslint-disable */
/**
 * Unescape HTML special characters from a given string
 *
 * @param {String} code The string that you want to unescape
 * @returns {String} The unescaped string
 */
function unescapeHtml(code){
    return code
        .replaceAll('&amp;', '&')
        .replaceAll('&lt;', '<')
        .replaceAll('&gt;', '>')
        .replaceAll('&quot;', '"')
        .replaceAll('&#039;', "'");
}
