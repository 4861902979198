// @ts-check

// Validates the form and sends the data to the Salesforce API

// DEMO variable
// const campaign=true;

function getCampaignFromURL() {
    const urlParams = new URLSearchParams(window.location.search);
    const campaignParam = urlParams.get('campaign');
    const sanitizedParam = campaignParam ? campaignParam.replace(/</g, '').replace(/>/g, '').replace(/\s*/ig, '') : '';
    return sanitizedParam;
}

function getAllowedCampaign(){
    const campaign = getCampaignFromURL();
    const isCampaignAllowed = isCampaign();
    const campaignString = isCampaignAllowed && campaign !== '' ? campaign : 'strato_de/WAY/Rückrufanfrage';
    return campaignString;
}

function isCampaign() {
    var isAllowed = !localStorage.getItem('campaign') || localStorage.getItem('campaign') === 'true';
    // Read the campaign variable from URL and local storage
    return isAllowed;
}

function generateHiddenFields(campaign) {
    return `
        <input type="hidden" name="7011v0000008IInAAM" id="7011v0000008IInAAM" value="${campaign}">
        <input type="hidden" name="campaign" id="campaign" value="${campaign}">
        <input type="hidden" name="00N7Q00000Grotl" id="00N7Q00000Grotl" value="${campaign}">
    `;
}

function addHiddenFields() {
    const form = document.getElementById('form_initial_contact');
    const campaign = getAllowedCampaign();
    const hiddenFields = generateHiddenFields(campaign);
    if (form) {
        form.insertAdjacentHTML('beforeend', hiddenFields);
    }
}

function copyIndustryValue() {
    var industryField = document.getElementById('industry');
    var industri2Field = document.getElementById('00N7Q00000Grotf');

    if (industryField && industri2Field && industryField instanceof HTMLInputElement && industri2Field instanceof HTMLInputElement) {
        var industryValue = industryField.value;
        industri2Field.value = industryValue;
    }
}

function copyTimeValue() {
    var timeField = document.getElementById('zeit');
    var availabilityField = document.getElementById('availability');
    var time2Field = document.getElementById('00N7Q00000GrotZ');

    if (timeField instanceof HTMLSelectElement &&
        availabilityField instanceof HTMLInputElement &&
        time2Field instanceof HTMLInputElement) {

        var timeValue = timeField.value;
        availabilityField.value = timeValue;
        time2Field.value = timeValue;
    }
}

function copyMessageValue() {
    var messageField = document.getElementById('message');
    var descriptionField = document.getElementById('description');

    if (messageField instanceof HTMLTextAreaElement && descriptionField instanceof HTMLInputElement) {
        var messageValue = messageField.value;
        descriptionField.value = messageValue;
    }
}

// Function to validate the form and send data to the Salesforce API
function handleFormSubmit(event) {
    event.preventDefault();
    var form = document.getElementById('form_initial_contact');
    if (form && validateForm(form)) {
        copyIndustryValue();
        copyTimeValue();
        copyMessageValue();
        submitFormData(form);
    }
}

// Function to run on document load
document.addEventListener('DOMContentLoaded', function () {
    var form = document.getElementById('form_initial_contact');
    if (form) {
        addHiddenFields(); // Add hidden fields based on campaign
        form.addEventListener('submit', handleFormSubmit);
    }
});

// Validation Helper Functions
function addValidationClasses(element, isValid) {
    if (isValid) {
        element.classList.remove('is-invalid');
        element.classList.add('is-valid');
    } else {
        element.classList.remove('is-valid');
        element.classList.add('is-invalid');
    }
}

function validateEmail(inputElement) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    addValidationClasses(inputElement, emailRegex.test(inputElement.value));
}

function validatePLZ(inputElement) {
    const customRegex = /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/;
    addValidationClasses(inputElement, customRegex.test(inputElement.value));
}

function validateCity(inputElement) {
    const customRegex = /^[a-zA-Z0-9-_.,"/ öäüÖÄÜ]*$/;
    addValidationClasses(inputElement, customRegex.test(inputElement.value));
}

function validateName(inputElement) {
    const customRegex = /^[a-zA-Z0-9-_.,"/ öäüÖÄÜ]*$/;
    addValidationClasses(inputElement, customRegex.test(inputElement.value));
}

function validatePhone(inputElement) {
    const customRegex = /^[0-9+ ()]*$/;
    addValidationClasses(inputElement, customRegex.test(inputElement.value));
}

// Main Validation Dispatcher
function validate(inputElement) {
    switch (inputElement.dataset.regex) {
    case 'email': validateEmail(inputElement); break;
    case 'plz': validatePLZ(inputElement); break;
    case 'city': validateCity(inputElement); break;
    case 'name': validateName(inputElement); break;
    case 'phone': validatePhone(inputElement); break;
    // default: console.warn('No validation for type:', inputElement.dataset.regex);
    }
}

// Validate Form Logic
function validateForm(form) {
    var isValid = form.checkValidity();
    Array.from(form.querySelectorAll('[data-regex]')).forEach(validate);
    updateFormValidationState(form, isValid);
    return isValid && !form.querySelector('.is-invalid');
}

// Update Form Validation State
function updateFormValidationState(form, isValid) {
    if (!isValid || form.querySelector('.is-invalid')) {
        form.classList.add('was-validated');
    }
}

// Submit Form Data
function submitFormData(form) {
    sendToSalesforcePOST(form).then(() => {
        hideFormShowThankYou(form);
    });
}

// Salesforce POST Request
function sendToSalesforcePOST(form) {
    var baseUrl = '/buy/ger/salesforce/send_contact_request?encoding=UTF-8';
    var formData = new FormData(form);
    return fetch(baseUrl, { method: 'POST', body: formData, mode: 'no-cors' });
}

// Show Thank You Message and Hide Form
function hideFormShowThankYou(form) {
    form.style.display = 'none';
    showThankYouMessage();
}

// Display Thank You Message
function showThankYouMessage() {
    var thankYouMessage = document.getElementById('thankYouMessage');
    if (thankYouMessage) {
        thankYouMessage.style.display = 'block';
        window.scrollTo(0, thankYouMessage.offsetTop - 100);
    }
}

