import './modules/navi.js';
import './modules/footer.js';
import './modules/accordion.js';
import './modules/prg-btn.js';
import './modules/flipcard.js';
import './modules/swiper.js';
import './modules/subnav.js';
import './modules/langswitch.js';
import './modules/vlzswitch.js';
import './modules/tooltip.js';
import './modules/modal.js';
import './modules/3steps_image.js';
import './modules/pricebox.js';
import './modules/domainchecker.js';
import './util/currentbreakpoint.js';
import './modules/mailto.js';
import './modules/table_feature.js';
import './modules/consent/consent.js';
import './modules/contentmenu.js';
import './modules/pricebox-lg.js';
import './modules/productswitcher2.js';
import './modules/getabtests.js';
import './modules/readmore.js';
import './modules/selectBoxes.js';
import './modules/shuffle.js';
import './modules/sslswitch.js';
import { showYoutubeVideos } from './modules/youtube/youtube.js';
import './modules/carousel';
import './modules/library_codeBtn.js';
import { showProducts } from './modules/basket/basket.js';
import './modules/t_cookie.js';
import './modules/seo_inhaltsverzeichnis.js';
import './modules/domainaccordion.js';
import './modules/rankingcoach.js';
import './modules/pricebox-bundle.js';
import './modules/form.js';
import './modules/pricecard_text_checkmark_dynamic.js';
import './modules/form_initial_contact.js';
import './modules/filtered-table.js';
import './modules/timeswitch.js';
import './modules/tabswitch.js';
import './modules/check-to-storage.js';
import './modules/cookie-visitor.js';

showProducts();
showYoutubeVideos();
