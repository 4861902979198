//@ts-check

/**
 * @summary keybord accesebility for vlzswitch and prevent spacebar from scrolling down
 */

import SELECT from '../dom/selector-engine';

const vlzswitches = SELECT.find('.vlzswitch');
const [vlzswitchScope1, vlzswitchScope2] = SELECT.find('.vlzswitch__scope');

/**
 * Toggles the value of the attribute 'aria-checked' of the given element.
 *
 * @param {HTMLElement} element - The element to toggle the 'aria-checked' attribute.
 * @return {string} The new value of the 'aria-checked' attribute.
 */
const toggleAriaChecked = (element) => {
    return element.getAttribute('aria-checked') === 'true' ? 'false' : 'true';
};

/**
 * Sets the checked state of an element based on the value of toggleAriaChecked.
 *
 * @param {HTMLElement} element - The element to set the checked state for.
 * @return {void}
 */
const setChecked = (element) => {
    if (toggleAriaChecked(element) === 'true') {
        element.setAttribute('aria-checked', 'true');
        vlzswitchScope1.checked = true;
    } else {
        element.setAttribute('aria-checked', 'false');
        vlzswitchScope2.checked = true;
    }
};

const changeCheckbox = event => {
    const eventTarget = event.target;
    const spacebarKeyCode = 32;
    const isSpacebar = event.keyCode === spacebarKeyCode;
    const isCheckbox = eventTarget.classList.contains('vlzswitch');
    if (!isSpacebar || !isCheckbox) {
        return;
    }
    setChecked(eventTarget);
};

const addEventListenerToCheckbox = element => {
    element.addEventListener('keydown', changeCheckbox);
};

/**
 * Disables the scroll down behavior when the spacebar is pressed
 */
const disableSpacebarScrolldown = () => {
    /**
     * Handles the keydown event on the window.
     *
     * @param {object} event - The keydown event object.
     * @return {boolean} Returns true if the event target does not have the class "vlzswitch" and the key code is not 32. Otherwise, returns false.
     */
    window.onkeydown = function (event) {
        if(event.target.classList.contains('vlzswitch')){
            return !(event.keyCode == 32);
        } else {
            return true;
        }
    };
};

vlzswitches.forEach(element => {
    addEventListenerToCheckbox(element);
});

disableSpacebarScrolldown();
