import SELECT from '../dom/selector-engine';

const modalButtons = SELECT.find('[data-toggle=\'modal\']');
const modalCloseBtns = SELECT.find('.modal .close');

document.body.addEventListener('click', (e) => {
    if(e.target.matches('.modal-wrapper') && !e.target.classList.contains('fixed')) {
        closeModal(e.target);
    }
});

modalButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
        e.preventDefault();
        const modal = SELECT.findOne('#'+ e.currentTarget.dataset.target);
        modal.classList.add('d-flex');
        document.body.classList.add('modal-open');
    });
});

modalCloseBtns.forEach((button) => {
    button.addEventListener('click', (e) => {
        e.preventDefault();
        const parentModal = SELECT.parents(e.currentTarget, '.modal-wrapper');
        closeModal(parentModal[0]);
    });
});

const closeModal = (modal) => {
    modal.classList.remove('d-flex');
    document.body.classList.remove('modal-open');
};
