import { langConfig } from '../config/langConfig';
// get langCode from html lang attribute or set default
export const langCode = document.documentElement.lang || 'de';

export const getlangConfig = (key, lang=langCode, theLangconfig=langConfig) => {
    return theLangconfig[lang] ? theLangconfig[lang][key] : null;
};
const lang = getlangConfig('lang');

// ohne https://
// var url = 'webmail.strato.com/appsuite/signin';
// console.log(url.replaceAll('/', '%').replaceAll('.', '$').split('').reverse().join(''));

var createFormPrg = function () {
    if (document.querySelector('form[name=redirect]') === null) {
        // wenn kein Form vorhanden, wird es erzeugt und eingefügt
        var formContainer = document.createElement('div');
        formContainer.style.display = 'none';
        formContainer.innerHTML = '<form name="redirect" action="" method="post"><input name="target" value=""></form>';
        document.body.appendChild(formContainer);
    } else {
        return;
    }
};

document.addEventListener('click', function (e) {
    if (e.target.dataset.buy !== undefined) {
        createFormPrg();
        document.querySelector('form[name=redirect]').setAttribute('action', `/buy/${lang}/basket/addshortname`);
        // holt sich das Value von data-buy
        document.querySelector('form[name=redirect] input').value = e.target.getAttribute('data-buy');
        document.querySelector('form[name=redirect]').submit();
    }
});
document.addEventListener('click', function (e) {
    if (e.target.dataset.prg !== undefined) {
        createFormPrg();
        // holt sich das Value von data-prg
        var target = e.target.getAttribute('data-prg');
        //encoding
        target = target.replaceAll('%', '/').replaceAll('$', '.').split('').reverse().join('');
        document.querySelector('form[name=redirect]').setAttribute('action', 'https://' + target);
        document.querySelector('form[name=redirect]').submit();
    }
});
