/**
 * get all abtests from the page
 * @returns {Array}
*/
const getabtests = () => {
    const abtests = [];
    const abtestElements = document.querySelectorAll('[data-abtest][data-abtest-case]');
    abtestElements.forEach(element => {
        const { dataset } = element;
        const { abtest } = dataset || {};
        //check if abtest is already in array
        if (abtest && abtests.indexOf(abtest) === -1) {
            abtests.push(abtest);
        }
    });
    return abtests;
};
/**
 * hide all abtests except the first one
 * @param {Array} abtests
 * @returns {Array}
 */
const hideabtests = () => {
    const abtests = getabtests();
    abtests.forEach(abtest => {
        const abtestElements = document.querySelectorAll(`[data-abtest="${abtest}"]`);
        abtestElements.forEach(element => {
            const { dataset } = element;
            const { abtestCase } = dataset || {};
            if (abtestCase !== '1') {
                //add class to hide element
                element.classList.add('d-none');
            }
        });
    });
};
hideabtests();
