// @ts-check
//disable eslint for this file
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-inner-declarations */

// if server is in url

// get all .product__switch elements

/** @type {NodeListOf<HTMLInputElement>|null} */
const product__switchers = document.querySelectorAll('.product__switch');

// function to get the selected value from the .product__switch dropdown
const getSelectedValue = (/** @type {HTMLInputElement} */ product__switcher) => {
    return product__switcher.value;
};

const productSwitchers = document.querySelectorAll('.product__switch');

/**
 * Handles the change event of the dropdown.
 *
 * @param {HTMLElement} productSwitcher - The dropdown element.
 * @return {void} This function does not return anything.
 */
const handleDropdownChange = (productSwitcher) => {
    const selectedValue = getSelectedValue(productSwitcher);
    const dataName = productSwitcher.getAttribute('data-name') || '';
    syncDropdowns(selectedValue, dataName);
};

const handleProductSwitcherChange = (event) => {
    handleDropdownChange(event.target);
};

productSwitchers.forEach((productSwitcher) => {
    productSwitcher.addEventListener('change', handleProductSwitcherChange);
});

/**
 * Synchronizes the dropdowns with the selected value.
 *
 * @param {any} selectedValue - The value to set for the dropdowns.
 * @param {string} dataName - The data name attribute of the dropdowns to synchronize.
 */
function syncDropdowns(selectedValue, dataName) {
    const switchers = document.querySelectorAll(`.product__switch[data-name="${dataName}"]`);
    switchers.forEach((productSwitcher) => {
        productSwitcher.value = selectedValue;
    });
}

productSwitchers.forEach((productSwitcher) => {
    handleDropdownChange(productSwitcher);
});

document.querySelectorAll('[data-related="1"]').forEach(element => {
    //element.classList.add('d-none');
});

// all .pricecard elements
const pricecards = document.querySelectorAll('.pricecard');


pricecards.forEach(pricecard => {
    //data-related="0"
    const dataRelated = pricecard.getAttribute('data-related') || '';
    //data-name
    const dataName = pricecard.getAttribute('data-name') || '';
    //product__switch element
    const product__switchWithSameDataName = document.querySelectorAll(`.product__switch[data-name="${dataName}"]`);
    const pricecardWithSameDataName = document.querySelectorAll(`.pricecard[data-name="${dataName}"]`);
    const product__switch = pricecard.querySelector('.product__switch');

    /**
     * Updates the display of pricecards based on the selected product switch option.
     *
     * @return {void}
     */
    function updatePricecardsDisplay() {
        if (!product__switch) return;

        const dataValue = product__switch.options[product__switch.selectedIndex].dataset.value || '';
        pricecardWithSameDataName.forEach(pricecard => {
            const dataRelated = pricecard.getAttribute('data-related') || '';
            if (dataRelated === dataValue) {
                pricecard.classList.remove('d-none');
                pricecard.classList.add('d-block');
            } else {
                pricecard.classList.add('d-none');
                pricecard.classList.remove('d-block');
            }
        });
    }

    if (product__switch) {
        product__switch.addEventListener('change', updatePricecardsDisplay);
        updatePricecardsDisplay();
    }

});
pricecards.forEach(pricecard => {
    //.product__switch element
    const product__switch = pricecard.querySelector('.product__switch');
    if (!product__switch) {
        return;
    }
    product__switch.addEventListener('change', function () {
        //data-name of the .product__switch element
        const dataName = product__switch.getAttribute('data-name') || '';
        // get data-value of selected option
        const dataValue = product__switch.options[product__switch.selectedIndex].dataset.value;
        // get all .table__cell elements with the same data-name
        const table__cellswithDataName = document.querySelectorAll(`.table__cell[data-shortname="${dataName}"]`);
        // remove .hidden class from all spans inside table__cells where data-related_index === dataValue
        table__cellswithDataName.forEach(table__cell => {
            // get the spans inside the table__cell
            const spans = table__cell.querySelectorAll('span[data-related_index]');
            spans.forEach(span => {
                // data-related_index of the span
                const dataRelatedIndex = span.getAttribute('data-related_index') || '';
                if (dataRelatedIndex === dataValue) {
                    span.classList.remove('hidden');
                } else {
                    span.classList.add('hidden');
                }
            });
        });
    });
});

