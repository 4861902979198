/* eslint-disable no-unused-vars */
//@ts-check
import SELECT from '../dom/selector-engine';
const contentmenu = SELECT.findOne('#contentmenu');

if(contentmenu) {
    const contentmenuOffset = contentmenu.offsetTop;
    const contentmenuHeight = contentmenu.offsetHeight + parseFloat(window.getComputedStyle(contentmenu).marginBottom);
    const contentmenuLinks = SELECT.find('.content-menu-link');

    contentmenuLinks.forEach(link => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            const id = e.target.getAttribute('href');
            const elem = SELECT.findOne(id);
            let scrollOffset;
            if(!contentmenu.classList.contains('fixed-top')) {
                scrollOffset = elem.offsetTop - contentmenuHeight - contentmenu.offsetHeight;
            } else {
                scrollOffset = elem.offsetTop - contentmenuHeight;
            }
            window.scrollTo({top: scrollOffset, behavior: 'smooth'});
        });
    });

    window.addEventListener('scroll', function() {
        if (window.scrollY > contentmenuOffset + contentmenuHeight) {
            contentmenu.classList.add('fixed-top');
        } else {
            contentmenu.classList.remove('fixed-top');
        }
    });
}


