
var sslswitch1 = document.querySelectorAll('.sslswitch label[for="ssl1"]');
var sslswitch2 = document.querySelectorAll('.sslswitch label[for="ssl2"]');
const jsshowssl1 = document.querySelectorAll('.js-show-ssl');
const jsshowssl2 = document.querySelectorAll('.js-show-wildcard');

//add hide class to all sslswitch2 elements
jsshowssl2.forEach((jsshowssl2) => {
    jsshowssl2.classList.add('hidden');
});

sslswitch1.forEach((sslswitch1) => {
    sslswitch1.classList.add('sslswicht--active');
    sslswitch1.addEventListener('click', () => {
        ssl1active();
        jsshowssl1.forEach((jsshowssl1) => {
            jsshowssl1.classList.remove('hidden');
        });
        jsshowssl2.forEach((jsshowssl2) => {
            jsshowssl2.classList.add('hidden');
        });
    });
});

sslswitch2.forEach((sslswitch2) => {
    sslswitch2.classList.remove('sslswicht--active');
    sslswitch2.addEventListener('click', () => {
        ssl2active();
        jsshowssl1.forEach((jsshowssl1) => {
            jsshowssl1.classList.add('hidden');
        });
        jsshowssl2.forEach((jsshowssl2) => {
            jsshowssl2.classList.remove('hidden');
        });
    });
});

function ssl1active() {
    sslswitch1.forEach((sslswitch1) => {
        sslswitch1.classList.add('sslswicht--active');
    });
    sslswitch2.forEach((sslswitch2) => {
        sslswitch2.classList.remove('sslswicht--active');
    });
}

function ssl2active() {
    sslswitch1.forEach((sslswitch1) => {
        sslswitch1.classList.remove('sslswicht--active');
    });
    sslswitch2.forEach((sslswitch2) => {
        sslswitch2.classList.add('sslswicht--active');
    });
}

ssl1active();
