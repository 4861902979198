// eslint-disable-next-line no-unused-vars
import SELECT from '../dom/selector-engine';

let labels;
let contentParts;

// Funktion, die aufgerufen wird, wenn ein Sprachumschalter-Label geklickt wird
function handleToggleClick(event) {
    const target = event.target;
    // @ts-ignore
    if (target && target.tagName === 'LABEL') {
        // @ts-ignore
        // Die ausgewählte Sprache aus dem "for"-Attribut des Labels abrufen
        const lang = target.getAttribute('for');
        // Inhaltsbereiche basierend auf der ausgewählten Sprache umschalten
        toggleContentParts(lang);
        // Das angeklickte Label als aktiv markieren
        markActiveLabel(target);
    }
}

// Funktion zum Umschalten der Inhaltsbereiche basierend auf der ausgewählten Sprache
function toggleContentParts(lang) {
    for (const contentPart of contentParts) {
        // Den entsprechenden Inhaltsbereich für die ausgewählte Sprache aktivieren oder deaktivieren
        toggleContentPart(contentPart, lang);
    }
}

// Funktion zum Aktivieren oder Deaktivieren eines bestimmten Inhaltsbereichs
function toggleContentPart(contentPart, lang) {
    // Überprüfen, ob der Inhaltsbereich mit der ausgewählten Sprache übereinstimmt
    const shouldBeActive = contentPart.getAttribute('data-lang') === lang;
    contentPart.classList.toggle('active', shouldBeActive);
}

// Funktion zum Markieren des angeklickten Labels als aktiv
function markActiveLabel(activeLabel) {
    for (const label of labels) {
        // Das Label als aktiv oder inaktiv markieren
        markLabelActive(label, activeLabel === label);
    }
}

// Funktion zum Markieren eines bestimmten Labels als aktiv oder inaktiv
function markLabelActive(label, isActive) {
    label.classList.toggle('langswitchLabel--active', isActive);
}

// Funktion zum Sanitieren von URL-Parametern, um Code-Injektion zu verhindern
function sanitize(input) {
    if (!input) return ''; // Abfangen von null oder undefined und Rückgabe eines leeren Strings
    input = input.replace(/\/([^.|/]*\.html)/, '/'); // Ersetzen von HTML-Seitenerweiterung mit "/"
    input = input.replace(/[#|?].*$/, ''); // Entfernen von zusätzlichen Abfragezeichen und Anker
    input = input.replace(/[&<>"'`=/]/g, ''); // Entfernen von potenziell schädlichen Zeichen
    return input;
}

function getURLParameter(name) {
    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get(name);
    return sanitize(paramValue);
}

// Event-Listener, der beim Laden der Seite aufgerufen wird
document.addEventListener('DOMContentLoaded', function() {
    // Den Sprachumschalter und alle Inhaltsbereiche abrufen
    const toggler = document.querySelector('.langswitch');
    contentParts = document.querySelectorAll('.langswitch__content');
    // Überprüfen, ob der Sprachumschalter existiert
    if (toggler) {
        labels = toggler.getElementsByTagName('label') || [];
        toggler.addEventListener('click', handleToggleClick);
        // Die initiale Sprache aus dem URL-Parameter abrufen
        const initialLang = getURLParameter('lang');
        if (initialLang) {
            // Das entsprechende Label anklicken, um die Sprache zu aktivieren
            const labelToClick = document.querySelector(`label[for="${initialLang}"]`);
            if (labelToClick) {
                labelToClick.click();
            }
        }
    }
});
