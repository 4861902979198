import SELECT from '../dom/selector-engine';

const isMobile = document.body.dataset.device === 'xs';
const readmoreBtn = SELECT.find('.read-more-btn');

if(isMobile && readmoreBtn.length > 0) {
    readmoreBtn.forEach((btn) => {
        btn.addEventListener('click', (e) => {
            const elementText = e.target.previousElementSibling;
            const readMoreBtn = e.target;
            const buttonAttributeText  = e.target.dataset.text;
            const buttonContentText = e.target.innerText;

            readMoreBtn.innerText = buttonAttributeText;
            readMoreBtn.dataset.text = buttonContentText;
            elementText.classList.toggle('d-block');
            elementText.classList.toggle('expanded');
        });
    });
}

