// IIFE (Immediately Invoked Function Expression) für den Codebereich
(function() {
    // Hinzufügen des Event-Listeners für das 'consent_changed'-Event am 'body'-Element
    document.body.addEventListener('consent_changed', handleConsentChanged);

    // Hinzufügen des Event-Listeners für das 'DOMContentLoaded'-Event
    document.addEventListener('DOMContentLoaded', handleConsentChanged);

    // Funktion zum Behandeln der Zustimmungsänderung
    function handleConsentChanged() {
        var consentValue = document.body.getAttribute('data-consent');
        if (consentValue === '1111') {
            // Überprüfen, ob der Cookie vorhanden ist und entsprechend setzen
            if (!getCookie('VisitorTagger')) {
                setCookie('VisitorTagger', 'UserNew', 30);
            } else if (getCookie('VisitorTagger') !== 'UserLogged') {
                setCookie('VisitorTagger', 'UserBack', 60);
            }
        }
    }

    // Funktion zum Abrufen eines Cookies anhand seines Namens
    function getCookie(name) {
        var cookieName = name + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var cookieArray = decodedCookie.split(';');
        for (var i = 0; i < cookieArray.length; i++) {
            var cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return null;
    }

    // Funktion zum Setzen eines Cookies
    function setCookie(name, value, days) {
        var expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + days);
        var cookieValue = encodeURIComponent(value) + '; expires=' + expirationDate.toUTCString() + '; path=/';
        document.cookie = name + '=' + cookieValue;
    }
})();
