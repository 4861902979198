/* eslint-disable no-unused-vars */
/* eslint-disable max-lines-per-function */

// Select all panel elements and accordion boxes
const allPanelElems = document.querySelectorAll('.accordion__box .accordion__panel');
const accordionBoxes = document.querySelectorAll('.accordion__box');

// Add event listener to the document body
document.body.addEventListener('click', (event) => {
    const { target } = event;

    // Check if the clicked element or its parent has the class 'panel-title'
    const isPanelTitle = target.classList.contains('panel-title') || target.parentElement.classList.contains('panel-title');

    if (isPanelTitle) {
        const accordionPanel = target.closest('.accordion__panel');
        togglePanel(accordionPanel);
    }
});

const togglePanel = (panel) => {
    if (!panel.classList.contains('accordion__box--active') && panel.dataset.js !== 'contentmenu-btn') {
        const parent = panel.parentElement.parentElement.parentElement;
        removeActiveClasses(parent);
        panel.classList.add('accordion__box--active');
        panel.querySelector('.accordion__content').style.height = panel.querySelector('.accordion__content > div').offsetHeight+24 + 'px';
    } else {
        removeActiveClasses(panel.parentElement.parentElement.parentElement);
    }
};

const removeActiveClasses = (parent) => {
    const panelElems = parent.querySelectorAll('.accordion__panel');
    panelElems.forEach((element) => {
        element.classList.remove('accordion__box--active');
        const accordionContent = element.querySelector('.accordion__content');
        if (accordionContent) {
            accordionContent.style.height = '0px';
        }
    });
};

const changeSrcAttributOf = (
    panelImgNode,
    elementDataset,
    elementImgAlt,
    elementImgClassModifier
) => {
    panelImgNode.forEach(element => {
        if (!elementDataset) return;
        element.src = elementDataset;
        element.alt = elementImgAlt || '';
        if (elementImgClassModifier) {
            element.classList.add(elementImgClassModifier);
        }
    });
};


const getParent = (elem, selector) => {
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }
    return null;
};
accordionBoxes.forEach(changeThisImageWithDataSetAttributes());

const loadPanel = () => {
    allPanelElems.forEach(element => {
        const isPanelActive = element.classList.contains('accordion__box--active');
        const accordionContent = element.querySelector('.accordion__content');
        const accordionContentFirstDiv = element.querySelector('.accordion__content > div');
        // need offsetHeight plus margin
        const accordionContentFirstDivHeight = accordionContentFirstDiv ? accordionContentFirstDiv.offsetHeight : 0;
        if (isPanelActive && accordionContent) {
            accordionContent.style.height = accordionContentFirstDivHeight + 'px';
        }
    });
};
window.addEventListener('resize', function() {
    loadPanel();
});
function changeThisImageWithDataSetAttributes() {
    return (element) => {
        const parentRow = getParent(element, '.row');
        if (!parentRow) {
            return;
        }
        const panelImgNodes = parentRow.querySelectorAll('img.panel-dynamic--img') || [];
        //destructuring the dataset
        const { panelImg, panelImgAlt, panelImgClassModifier } = element.dataset;
        element.addEventListener('click', () => {
            changeSrcAttributOf(panelImgNodes, panelImg, panelImgAlt, panelImgClassModifier);
        });
    };
}
