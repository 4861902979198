/* eslint-disable max-lines-per-function */
document.addEventListener('DOMContentLoaded', function() {
    // Wähle alle Tab-Elemente innerhalb des Elements mit der Klasse "tabswitch" aus
    const tabs = document.querySelectorAll('.tabswitch a');
    // Wähle alle Elemente aus, die das Attribut "data-select" besitzen
    const selectedElements = document.querySelectorAll('[data-select]');

    // Füge jedem Tab ein Klick-Ereignis hinzu
    tabs.forEach((tab) => {
        tab.addEventListener('click', function(e) {
            e.preventDefault(); // Verhindere das Standardverhalten des Links (Seitenneuladen)

            // Überprüfe, ob im Tab der Datensatz "showall" auf "true" gesetzt wurde
            const showAll = tab.dataset.showall === 'true';
            // Initialisiere die Variable für die ausgewählte Kategorie
            let selectedCategory = '';

            // Extrahiere die Kategorie aus dem Text des Tabs
            selectedCategory = tab.textContent.trim().toLowerCase().replace(/\s+/g, '');

            // Entferne die Klasse "active" von allen Tabs
            tabs.forEach(t => t.classList.remove('active'));
            // Fügt die Klasse "active" zum geklickten Tab hinzu
            tab.classList.add('active');

            // Zeige oder verberge Elemente basierend auf der ausgewählten Kategorie oder dem "showAll"-Parameter
            selectedElements.forEach(element => {
                const select = element.dataset.select;
                // Wenn "showAll" true ist, zeige alle Elemente an
                if (showAll) {
                    element.style.display = '';
                // Andernfalls zeige nur die Elemente an, die zur ausgewählten Kategorie gehören
                } else if (select === selectedCategory) {
                    element.style.display = '';
                // Verberge alle anderen Elemente
                } else {
                    element.style.display = 'none';
                }
            });
        });
    });

    // Suche nach dem aktuell aktiven Tab
    initializeActiveTab(tabs);
});

function initializeActiveTab(tabs) {
    // Suche nach dem aktuell aktiven Tab
    const activeTab = document.querySelector('.tabswitch a.active');
    if (activeTab) {
        // Löse ein Klick-Ereignis auf dem aktiven Tab aus, um den Anfangszustand festzulegen
        activeTab.click();
    } else {
        // Fallback: Wenn kein aktiver Tab vorhanden ist, klicke auf den ersten Tab
        // Annahme: 'tabs' ist eine bereits definierte Variable, die alle Tabs enthält
        if (tabs && tabs.length > 0) {
            tabs[0].click();
        }
    }
}
