/* eslint-disable max-lines-per-function */
// @ts-check

document.addEventListener('DOMContentLoaded', function() {
    var timeLabels = document.querySelectorAll('label[for="time1"], label[for="time2"]');
    var textItems = document.querySelectorAll('.pricecard__plandisclaimer li');
    // check if the labels and textItems are available
    if (!timeLabels.length || !textItems.length) {
        return;
    }

    timeLabels.forEach(function(label) {
        label.addEventListener('click', function() {
            var timeClass = label.getAttribute('for');
            textItems.forEach(function(item) {
                if (item.classList.contains(timeClass)) {
                    item.style.display = 'block';
                } else {
                    item.style.display = 'none';
                }
            });
        });
    });

    // Setze den Standardwert, um "pro Stunden" als aktiv zu setzen
    textItems.forEach(function(item) {
        if (item.classList.contains('time1')) {
            item.style.display = 'block';
        } else {
            item.style.display = 'none';
        }
    });
});
