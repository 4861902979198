/* eslint-disable max-lines-per-function */
import { createPopper } from '@popperjs/core';

(function() {
    const tooltip = () => {
        let tooltip = {};
        let popperInstance = null;
        const show = (tooltipElement) => {
            // Text of the tooltip
            const getText = () => {
                let textValue = '';
                if (tooltipElement.getAttribute('data-original-title')) {
                    textValue = tooltipElement.getAttribute('data-original-title');
                } else {
                    textValue = tooltipElement.getAttribute('title');
                    tooltipElement.setAttribute('data-original-title', textValue);
                    tooltipElement.removeAttribute('title');
                }
                return textValue;
            };
            // Insert the current tooltip as a separate element
            tooltipElement.insertAdjacentHTML('beforebegin', `<span class="tooltip" role="tooltip">${getText()}<span class="arrow" data-popper-arrow></span></span>`);
            tooltip = tooltipElement.previousElementSibling;

            // Position of the tooltip
            popperInstance = createPopper(tooltipElement, tooltip, {
                placement: 'top',
            });

            // Make the tooltip visible
            tooltip.setAttribute('data-show', '');

            // Enable the event listeners
            popperInstance.setOptions({
                modifiers: [
                    { name: 'eventListeners', enabled: true },
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 12],
                        },
                    }
                ],
            });

            // Update its position
            popperInstance.update();
        };

        const hide = () => {
            // Hide the tooltip
            document.querySelectorAll('[role="tooltip"]').forEach(element => element.remove());

            // Disable the event listeners
            popperInstance?.setOptions({
                modifiers: [{ name: 'eventListeners', enabled: false }],
            });
        };

        const showEvents = ['mouseenter', 'focus'];
        const hideEvents = ['mouseleave', 'blur'];

        //Events for the tooltip
        document.querySelectorAll('[data-toggle="tooltip"]').forEach(tooltipElement => {
            showEvents.forEach(event => tooltipElement.addEventListener(event, show.bind(this, tooltipElement)));
            hideEvents.forEach(event => tooltipElement.addEventListener(event, hide));
        });
    };
    tooltip();
    // Tooltipps in Popups
}());
