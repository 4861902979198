/* eslint-disable max-lines-per-function, complexity */

function addValidationClasses(element, isValid) {
    if (isValid) {
        element.classList.remove('is-invalid');
        element.classList.add('is-valid');
    } else {
        element.classList.remove('is-valid');
        element.classList.add('is-invalid');
    }
}

function validateEmail(inputElement) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    addValidationClasses(inputElement, emailRegex.test(inputElement.value));
}

function validatePLZ(inputElement) {
    const customRegex = /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/;
    addValidationClasses(inputElement, customRegex.test(inputElement.value));
}

function validateCity(inputElement) {
    const customRegex = /^[a-zA-Z0-9-_.,"/ öäüÖÄÜ]*$/;
    addValidationClasses(inputElement, customRegex.test(inputElement.value));
}

function validateName(inputElement) {
    const customRegex = /^[a-zA-Z0-9-_.,"/ öäüÖÄÜ]*$/;
    addValidationClasses(inputElement, customRegex.test(inputElement.value));
}

function validatePhone(inputElement) {
    const customRegex = /^[0-9+ ()]*$/;
    addValidationClasses(inputElement, customRegex.test(inputElement.value));
}



// Fetch all the forms we want to apply custom Bootstrap validation styles to
var forms = document.querySelectorAll('.needs-validation');

// Loop over them and prevent submission
Array.prototype.slice.call(forms).forEach(function (form) {
    form.addEventListener('submit', function (event) {
        // get all elements with data-regex
        var inputElements = form.querySelectorAll('[data-regex]');
        // validate all elements with data-regex
        inputElements.forEach(function (inputElement) {
            validate(inputElement);
        });
        if (!form.checkValidity() || form.querySelector('.is-invalid')) {
            event.preventDefault();
            event.stopPropagation();
        }
        form.classList.add('was-validated');
    }, false);

    // Add keyup event to input
    form.addEventListener('keyup', function (event) {
        //do nothing if key is tab
        if (event.keyCode === 9) {
            return;
        }
        //if form is !was-validated do nothing
        if (!form.classList.contains('was-validated')) {
            return;
        }

        var inputElement = event.target;
        if (inputElement.tagName === 'INPUT') {
            inputElement.classList.add('was-validated');
        }
        // if input has data-regex == email
        validate(inputElement);
    }, false);
});


function validate(inputElement) {
    if (inputElement.dataset.regex === 'email') {
        validateEmail(inputElement);
    } else if (inputElement.dataset.regex === 'plz') {
        validatePLZ(inputElement);
    } else if (inputElement.dataset.regex === 'city') {
        validateCity(inputElement);
    } else if (inputElement.dataset.regex === 'name') {
        validateName(inputElement);
    } else if (inputElement.dataset.regex === 'phone') {
        validatePhone(inputElement);
    }
}
