
const attribute = 'data-checkbox-to-storage';
const checkboxes = document.querySelectorAll('[' + attribute + ']');

checkboxes.forEach(function(element) {
    const storageID = element.getAttribute(attribute);
    const currentStorage = localStorage.getItem(storageID);
    let current = currentStorage === 'true' ? true : currentStorage === 'false' ? false : null;

    if(current === null){
        element.checked = element.checked == true ? true : false;
        localStorage.setItem(storageID, element.checked);
    } else {
        element.checked = current;
    }

    element.addEventListener('click', function() {
        localStorage.setItem(storageID, this.checked);
    });
});
