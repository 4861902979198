import shuffle from 'shufflejs';

const Shuffle = shuffle;
document.addEventListener('DOMContentLoaded', function() {
    // assign querySelector to variable
    const shuffleContainer = document.querySelector('.infografic-shuffle');
    // Check if infografic shuffle exists
    if (!shuffleContainer) {
        return;
    }
    new Shuffle(shuffleContainer, {
        /* itemSelector: '.infografic-shuffle_item', */
        sizer: '.infografic-shuffle_calibrator',
    });
});
