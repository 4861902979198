// different trackingConfigs to store in cookies
const trackingConfigs = [
    { cookieName: 'swtssaCookie', urlParameter: 'transaction_id' }, // tune
    { cookieName: 'swtsarCookie', urlParameter: 'trackID' }, // adrecord
];

const searchParams = new URLSearchParams(window.location.search);
const bodyTag = document.querySelector('body');
const setCookie = (name, value, days) => {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

const referrerFromDifferentDomain = () => {
    //referer is empty
    if (!document.referrer) {
        return false;
    }
    var currentUrl = new URL(document.location.href);
    var referrerUrl = new URL(document.referrer);
    //referrer from same domain
    if (currentUrl.hostname === referrerUrl.hostname) {
        return false;
    }
    //referrer from different domain
    return true;
};

const checkDataConsent =  (tag) => {
    const dataConsentAttribute = tag.getAttribute('data-consent');
    if(dataConsentAttribute === null) return false;

    const lastDigit = dataConsentAttribute[dataConsentAttribute.length - 1];
    if (lastDigit === '1') {
        return true;
    } else {
        return false;
    }
};


// on load and on consent_changed event

// Funktion, die den Code ausführt
const executeCode = (urlParameter, cookieName) => {
    const cookieContent = searchParams.get(urlParameter);
    if (checkDataConsent(bodyTag)) {
        if (cookieContent) {
            setCookie(cookieName, cookieContent, 30);
        }
        if (!cookieContent && referrerFromDifferentDomain()) {
            setCookie(cookieName, cookieContent, -1);
        }
    }
};

// Event-Listener für das "load"-Event
window.addEventListener('load', () => {
    trackingConfigs.forEach((config) => {
        executeCode(config.urlParameter, config.cookieName);
    });
});

// Event-Listener für das "consent_changed"-Event auf dem HTML-Tag
document.querySelector('body').addEventListener('consent_changed', () => {
    trackingConfigs.forEach((config) => {
        executeCode(config.urlParameter, config.cookieName);
    });
});
