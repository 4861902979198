/* eslint-disable max-lines-per-function */
const subNavSection = document.querySelector('[data-js="subNavSection"]');
if (subNavSection) {
    const contentAreas = document.querySelectorAll('.content-area');
    const subNavItems = document.querySelectorAll('.sub-nav .visible-links .sub-nav__item');
    const subNav = document.querySelector('.sub-nav');
    const subNavBtn = document.querySelector('.sub-nav button');
    const subNavVLinks = document.querySelector('.sub-nav .visible-links');
    const subNavHLinks = document.querySelector('.sub-nav .hidden-links');
    let breaks = [];

    window.addEventListener('scroll', function() {
        let current = '';
        contentAreas.forEach((contentArea) => {
            const contentAreaTop = contentArea.offsetTop;

            if (scrollY >= contentAreaTop - 62) {
                current = contentArea.getAttribute('id');
            }
        });
        subNavItems.forEach((li) => {
            const contentAreaTop = contentAreas[0]?.offsetTop || 0;
            if (li.classList.contains(current)) {
                li.classList.add('active');
            } else {
                if (scrollY >= contentAreaTop - 62) {
                    li.classList.remove('active');
                }
            }
        });
        if (subNavHLinks.querySelectorAll('.sub-nav__item.active').length > 0) {
            subNavBtn.classList.add('active');
        } else {
            subNavBtn.classList.remove('active');
        }
        subNavHLinks.classList.add('hidden');
    });

    const updateSubNav = () => {
        let availableSpace = subNavBtn.classList.contains('hidden') ? subNav.offsetWidth : subNav.offsetWidth - subNavBtn.offsetWidth - 34;
        // visible links overflowing sub nav
        if (subNavVLinks.offsetWidth > availableSpace) {
            breaks.push(subNavVLinks.offsetWidth);
            subNavHLinks.appendChild(subNavVLinks.lastElementChild);
            if (subNavBtn.classList.contains('hidden')) {
                subNavBtn.classList.remove('hidden');
            }
            // visible links not overflowing sub nav
        } else {
            if (availableSpace > breaks[breaks.length - 1]) {
                updateSubnavChilds(availableSpace);
            }
            if (breaks.length < 1) {
                subNavBtn.classList.add('hidden');
                subNavHLinks.classList.add('hidden');
            }
        }
        subNavBtn.setAttribute('count', subNavHLinks.children.length);
        if (subNavVLinks.offsetWidth > availableSpace) {
            updateSubNav();
        }
    };

    const updateSubnavChilds = (availableSpace) => {
        subNavVLinks.appendChild(subNavHLinks.lastElementChild);
        breaks.pop();

        if (availableSpace > breaks[breaks.length - 1]) {
            updateSubnavChilds(availableSpace);
        }
    };

    //resize listener
    window.addEventListener('resize', function() {
        updateSubNav();
    });

    //toggle active state on sub nav item when there isn't enough space
    subNavItems.forEach((subNavItem) => {
        subNavItem.addEventListener('click', () => {
            setTimeout(() => {
                const subNavSpaceItems = document.querySelectorAll('.sub-nav__item');
                subNavSpaceItems.forEach((subNavSpaceItem) => {
                    subNavSpaceItem.classList.remove('active');
                });
                subNavItem.classList.add('active');
            }, 900);
        });
    });

    //toggle hidden sub menu
    subNavBtn.addEventListener('click', () => {
        subNavHLinks.classList.toggle('hidden');
    });

    document.readyState === 'complete' ? updateSubNav() : window.addEventListener('load', updateSubNav);
}
