// main.js

// see https://github.com/antfu/vite-dynamic-import-polyfill-example
import dynamicImportPolyfill from 'dynamic-import-polyfill';

// This needs to be done before any dynamic imports are used.
dynamicImportPolyfill.initialize();

import '../_styles/main.scss';
import '../_javascript/main.js';
