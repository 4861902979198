//@ts-check
const getConsent = () => {
    const body = document.querySelector('body');
    const dconsent = body?.getAttribute('data-consent');
    // the last digit of the data-consent attribute
    const youtubeIsAllowed = (dconsent?.slice(-1) || '0') === '1';
    return youtubeIsAllowed;
};

const loadIframe = (videoContainer) => {
    const videoId = videoContainer.getAttribute('data-video-id');
    const videoIframe = videoContainer.querySelector('.youtube__iframe');
    const url = `https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0&autoplay=1`;
    // innerHTML wichtig, damit autoplay funktioniert
    videoIframe.innerHTML = `<iframe allow="autoplay" src="${url}" frameborder="0" allowfullscreen></iframe>`;
    videoIframe.querySelector('iframe').addEventListener('load', () => { // iframe geladen
        videoContainer.querySelector('.youtube__placeholder').classList.add('d-none');
    });
};

const prepareConsent = (videoContainer) => {
    const consentButton = videoContainer.querySelector('.youtube__consent-button');
    consentButton?.addEventListener('mousedown', () => { // akzeptiere consent
        loadIframe(videoContainer);
        videoContainer.setAttribute('data-consent-accepted', 'true');
    }, true);
};

export const showYoutubeVideos = () => {
    const videoContainers = document.querySelectorAll('.youtube__container');
    videoContainers.forEach((videoContainer) => {
        prepareConsent(videoContainer);
        videoContainer.addEventListener('mousedown', () => {
            const youtubeIsAllowed = getConsent();
            if(youtubeIsAllowed){
                loadIframe(videoContainer);
            }
        });
    });
};
