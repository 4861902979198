// @ts-nocheck

document.addEventListener('DOMContentLoaded', initialize);

function initialize() {
    const state = {
        currentDuration: '-12month',
        currentProductIndex: '2',
    };
    setupProductVisibility(state);
    setupButtonEvents(state);
    setupDurationChangeEvents(state);
}

function setupProductVisibility(state) {
    hideAllProducts();
    showCurrentProduct(state);
    setVisualFeedbackForButton(state);
}

function hideAllProducts() {
    const products = document.querySelectorAll('.product-month');
    products.forEach(product => product.style.display = 'none');
}

function showCurrentProduct(state) {
    const leftProduct = document.getElementById(`left-product-${state.currentProductIndex}${state.currentDuration}`);
    const rightProduct = document.getElementById(`right-product-${state.currentProductIndex}${state.currentDuration}`);
    const buyButton = document.getElementById(`buy-button-${state.currentProductIndex}${state.currentDuration}`);
    if (leftProduct) leftProduct.style.display = 'block';
    if (rightProduct) rightProduct.style.display = 'block';
    if (buyButton) buyButton.style.display = 'block';
}

function setupButtonEvents(state) {
    const buttons = document.querySelectorAll('.pricecard-text-checkmark__tap');
    buttons.forEach(button => {
        button.addEventListener('click', () => {
            state.currentProductIndex = button.getAttribute('data-product-target').split('-')[1];
            hideAllProducts();
            showCurrentProduct(state);
            updateVisualFeedbackForButtons(button, buttons);
        });
    });
}

function setVisualFeedbackForButton(state) {
    const preSelectedButton = document.querySelector(`input[data-product-target="product-${state.currentProductIndex}"]`);
    if (preSelectedButton) preSelectedButton.classList.add('bg--primary', 'font--white');
}

function updateVisualFeedbackForButtons(selectedButton, buttons) {
    buttons.forEach(btn => btn.classList.remove('bg--primary', 'font--white'));
    selectedButton.classList.add('bg--primary', 'font--white');
}

function setupDurationChangeEvents(state) {
    setupDurationChangeEvent('vlz1Month', '-1month', state);
    setupDurationChangeEvent('vlz12Month', '-12month', state);
}

function setupDurationChangeEvent(elementId, duration, state) {
    const element = document.getElementById(elementId);
    if (element) {
        element.addEventListener('click', () => {
            state.currentDuration = duration;
            hideAllProducts();
            showCurrentProduct(state);
        });
    }
}
