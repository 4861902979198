/* eslint-disable no-unused-vars */
/* eslint-disable max-lines-per-function */

import Swiper, { Navigation, Pagination, Keyboard, Autoplay } from 'swiper';

const autoplayOFF = document.querySelector('.swiper-autoplay-off');
const autoplayON = document.querySelector('.swiper-autoplay-on');

const swiperDefault =  {
    modules: [],
    centeredSlides: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
    keyboard: {
        enabled: true,
    }
};

if (autoplayOFF) {
    const swiperNoAutoplay = JSON.parse(JSON.stringify(swiperDefault));
    swiperNoAutoplay.modules.push(Navigation, Pagination, Keyboard);
    let swiper  = new Swiper('.swiper-autoplay-off', swiperNoAutoplay);
}

if (autoplayON) {
    const swiperAutoplay = JSON.parse(JSON.stringify(swiperDefault));
    swiperAutoplay.modules.push(Navigation, Pagination, Keyboard, Autoplay);
    swiperAutoplay.autoplay = {
        delay: 5000,
    };
    let swiperwithAutoplay = new Swiper('.swiper-autoplay-on', swiperAutoplay);
}
