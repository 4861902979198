// select a.mailaddress dom elements
const mailtos = document.querySelectorAll('a.mailaddress');

const setMailto = mailto => {
    // set mailto href
    mailto.href = 'mailto:' + mailto.dataset.name + '@' + mailto.dataset.domain + '.' + mailto.dataset.tld;
};

//attach click event listener to  mailtos
mailtos.forEach(mailto => {
    mailto.addEventListener('mousedown', () => {
        setMailto(mailto);
    });
});
