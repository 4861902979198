import SELECT from '../dom/selector-engine';

(function initInfo() {

    const allPanelElems = SELECT.find('.footer__info .footer__panel.footer__panel--seo');
    const allPanelElemsnot = SELECT.find('.footer__info .footer__panel.footer__panel--default');

    const togglePanelIsSeoFooter = panel => {
        const IsSeoFooter = panel.currentTarget.classList.contains('footer__panel--seo');
        const IsSeoFooterActive = panel.currentTarget.classList.contains('footer__info--active');

        if (IsSeoFooter && !IsSeoFooterActive) {
            showSeoFooter();
        } else {
            hideSeoFooter();
            panel.currentTarget.classList.remove('footer__info--active');
        }
    };

    const togglePanelIsnotSeoFooter = panel => {
        panel.currentTarget.classList.toggle('footer__info--active');
    };

    function showSeoFooter() {
        document.querySelectorAll('.footer__panel--seo').forEach(function (element) {
            element.classList.add('footer__info--active');
        });
    }

    function hideSeoFooter() {
        document.querySelectorAll('.footer__panel--seo').forEach(function (element) {
            element.classList.remove('footer__info--active');
        });
    }

    allPanelElems.forEach(function (element) {
        element.addEventListener('click', togglePanelIsSeoFooter);

    });

    allPanelElemsnot.forEach(function (element) {
        element.addEventListener('click', togglePanelIsnotSeoFooter);

    });
}());
