/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.1.3): dom/selector-engine.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

/**
 * Constants
 */

const SelectorEngine = {
    /**
     * Finds all elements that match the given selector within the specified element.
     *
     * @param {string} selector - The CSS selector to match elements.
     * @param {Element} [element=document.documentElement] - The element within which to search for matching elements.
     * @return {Array} An array of elements that match the selector.
     */
    find(selector, element = document.documentElement) {
        return [].concat(...Element.prototype.querySelectorAll.call(element, selector));
    },

    /**
     * Finds the first element in the DOM that matches the given selector within the specified element.
     *
     * @param {string} selector - The CSS selector to match elements against.
     * @param {HTMLElement} [element=document.documentElement] - The HTML element within which to search for the matching element. Defaults to the entire document.
     * @return {Element} The first matching element found, or null if no match was found.
     */
    findOne(selector, element = document.documentElement) {
        return Element.prototype.querySelector.call(element, selector);
    },

    /**
     * Returns an array of children of the given element that match the specified selector.
     *
     * @param {Element} element - The parent element.
     * @param {string} selector - The CSS selector to match against the children.
     * @return {Array<Element>} - An array of children elements that match the selector.
     */
    children(element, selector) {
        return [].concat(...element.children).filter(child => child.matches(selector));
    },

    /**
     * Returns an array of all the ancestors of the given element that match the specified selector.
     *
     * @param {Element} element - The element whose ancestors are to be found.
     * @param {string} selector - The CSS selector used to filter the ancestors.
     * @return {Array} An array of all the ancestors of the given element that match the specified selector.
     */
    parents(element, selector) {
        const parents = [];
        let ancestor = element.parentNode.closest(selector);

        while (ancestor) {
            parents.push(ancestor);
            ancestor = ancestor.parentNode.closest(selector);
        }

        return parents;
    },

    /**
     * Finds the previous element sibling of the given element that matches the specified selector.
     *
     * @param {Element} element - The element whose previous sibling is to be found.
     * @param {string} selector - The CSS selector to match against the previous sibling elements.
     * @return {Array<Element>} An array containing the previous sibling element(s) that match the selector.
     */
    prev(element, selector) {
        let previous = element.previousElementSibling;

        while (previous) {
            if (previous.matches(selector)) {
                return [previous];
            }

            previous = previous.previousElementSibling;
        }

        return [];
    },
    /**
     * Finds the next sibling element that matches the given selector.
     *
     * @param {Element} element - The element to start the search from.
     * @param {string} selector - The CSS selector to match against.
     * @return {Array<Element>} - An array containing the next sibling element that matches the selector, or an empty array if no match is found.
     */
    next(element, selector) {
        let next = element.nextElementSibling;

        while (next) {
            if (next.matches(selector)) {
                return [next];
            }

            next = next.nextElementSibling;
        }

        return [];
    }
};

export default SelectorEngine;
