/* eslint-disable max-lines-per-function */
{
    // fehlt noch Polyfill für IE 11 für customElements
    class domainchecker extends HTMLElement {
        constructor() {
            super();
            this.countryConfig();
            this.country = this.countryInit();
        }
        countryConfig() {
            this.countryConfig = {
                de: {
                    tld: 'de',
                    countryPath: 'ger',
                    buttonText: ['Domain prüfen', 'Suchen'],
                    placeholdertext: ['Wunschdomain eingeben ...', 'Wunschdomain'],
                    errorMessageCount: 'Bitte wählen Sie einen Domainnamen, der mindestens 3 und maximal 63 Zeichen enthält.',
                    errorMessageCharacter: 'Der Domainname enthält ungültige Zeichen.'
                },
                nl: {
                    tld: 'nl',
                    countryPath: 'dut',
                    buttonText: ['Domein checken', 'Checken'],
                    placeholdertext: ['Domeinnaam invoeren ...', 'Domeinnaam'],
                    errorMessageCount: 'Kies een domeinnaam van minimaal 3 en maximaal 63 tekens.',
                    errorMessageCharacter: 'De zoekfunctie kon niet worden gestart. Controleer je gewenste domeinnaam.'
                },
                se: {
                    tld: 'se',
                    countryPath: 'swe',
                    buttonText: ['Kontrollera domän', 'Kontrollera domän'],
                    placeholdertext: ['Hitta din önskedomän! ...', 'Hitta din önskedomän!'],
                    errorMessageCount: 'Välj ett domännamn med minimum 3 och maximalt 63 tecken.',
                    errorMessageCharacter: 'Din sökning innehåller ogiltiga tecken.'
                },
                es: {
                    tld: 'es',
                    countryPath: 'spa',
                    buttonText: ['Comprobar', 'Comprobar'],
                    placeholdertext: ['¡Elige tu dominio!', '¡Elige tu dominio!'],
                    errorMessageCount: 'Elige un nombre de dominio que contenga un mínimo de 3 y un máximo de 63 caracteres.',
                    errorMessageCharacter: 'No se ha podido realizar la búsqueda. Por favor, comprueba el nombre de dominio que deseas.'
                },
                fr: {
                    tld: 'fr',
                    countryPath: 'fre',
                    buttonText: ['', ''],
                    placeholdertext: ['', ''],
                    errorMessageCount: '',
                    errorMessageCharacter: ''
                },
                en: {
                    tld: 'uk',
                    countryPath: 'eng',
                    buttonText: ['', ''],
                    placeholdertext: ['', ''],
                    errorMessageCount: '',
                    errorMessageCharacter: ''
                }
            };
        }

        connectedCallback() {
            const childrenElemente = this.children;
            for (var i = 0; i < childrenElemente.length; i++) {
                this.removeChild(childrenElemente[i]);
            }
            this.textContent = '';
            this.creatForm();
        }

        countryInit() {
            const lang = document.getElementsByTagName('html')[0].getAttribute('lang');
            if (this.countryConfig[lang]) {
                return this.countryConfig[lang];
            } else {
                return this.countryConfig['se'];
            }
        }

        creatElement(elementName, appentTo) {
            let element = document.createElement(elementName);
            if (appentTo) {
                appentTo.appendChild(element);
            }
            return element;
        }

        creatForm() {
            let formElement = this.creatElement('form');
            let attributeArray;
            let textPlacholder = '...';
            let urlParameter = '';
            if (window.location.search && new window.URL(location.href).searchParams.get('domain')) {
                urlParameter = new URLSearchParams(window.location.search).get('domain');
            }

            if(this.country.placeholdertext[1]!='' && document.querySelector('html').className.split(' ').indexOf('mobile') > -1){
                textPlacholder =this.country.placeholdertext[1];
            }
            else if (this.getAttribute('placeholder')) {
                textPlacholder = this.getAttribute('placeholder');
            }
            // create  <label for="domain_search_name" class="sr-only">Sök efter domän</label>
            const labelElement = this.creatElement('label', formElement);
            labelElement.setAttribute('for', 'domain_search_name');
            labelElement.className = 'sr-only hidden';
            labelElement.textContent = this.country.buttonText[1];


            attributeArray = [
                { name: 'phase', value: 'Domainauswahl', type: 'hidden' },
                { name: 'domain_search_type', value: 'category', type: 'hidden' },
                { name: 'domain_search_tlds_normal_scope', value: 'false', type: 'hidden' },
                { name: 'domain_search_name', id: 'domain_search_name', value: urlParameter, type: 'text', maxlength: '63', placeholder: textPlacholder, class: 'searchfield' }
            ];

            for (let item of attributeArray) {
                const inputElement = this.creatElement('input', formElement);
                for (let attribut in item) {
                    inputElement.setAttribute(attribut, item[attribut]);
                    inputElement.classList.add('h5');
                    if (item[attribut] === 'searchfield') {
                        inputElement.addEventListener('keypress', event => {
                            var keycode = event.keyCode ? event.keyCode : event.which;
                            if (keycode == '13') {
                                this.checkInputValue();
                            }
                        });
                    }
                }
            }

            const buttonElement = this.creatElement('button', formElement);
            buttonElement.addEventListener('mouseup', this.checkInputValue.bind(this));
            buttonElement.setAttribute('aria-label', this.country.buttonText[1]);

            const span1 = this.creatElement('span', buttonElement);
            span1.textContent = this.getAttribute('data-buttonText');
            span1.className = 'd-none d-md-inline h5';

            this.creatElement('i', span1);

            const span2 = this.creatElement('span', buttonElement);
            span2.textContent = this.getAttribute('data-buttonText');
            span2.className = 'd-inline d-md-none h5';
            this.appendChild(formElement);
            this.creatElement('i', span2);
        }

        checkInputValue() {
            const formElement = this.querySelector('form');
            const inputField = this.querySelector('input[name="domain_search_name"]');

            inputField.pattern = '[0-9A-Za-zçæœŒßäëïöüÿãñõâêîôûáéíóúýàèìòùåÅÄËÏÖÜŸÃÑÕÂÊÎÔÛÁÉÍÓÚÝÀÈÌÒÙÇÆŒŒ.-]{3,63}';
            inputField.addEventListener('invalid', e => {
                e.preventDefault();
                let errorMessage = '';

                let isCount = /^.{3,63}$/.test(inputField.value);
                let isCharacter = /^[0-9A-Za-zçæœŒßäëïöüÿãñõâêîôûáéíóúýàèìòùåÅÄËÏÖÜŸÃÑÕÂÊÎÔÛÁÉÍÓÚÝÀÈÌÒÙÇÆŒŒ.-]+$/.test(inputField.value);
                let insertBreak = (!isCount && !isCharacter) ? ',<br>' : '';

                if (!isCount) {
                    errorMessage = this.country.errorMessageCount;
                }
                if (!isCharacter) {
                    errorMessage += insertBreak + this.country.errorMessageCharacter;
                }

                if (!this.querySelector('.errorMessage')) {
                    inputField.insertAdjacentHTML('afterend', `<p class="errorMessage">${errorMessage}</p>`);
                } else {
                    this.querySelector('.errorMessage').innerHTML = errorMessage;
                }
            });
            if (inputField.validity.valid) {
                formElement.setAttribute('method', 'post');
                formElement.setAttribute('action', `/buy/${this.country.countryPath}/basket/domainchecker`);
                formElement.submit();
            }
        }
    }
    if (!customElements.get('strato-domainchecker')) { customElements.define('strato-domainchecker', domainchecker); }

}
