/* eslint-disable no-unused-vars */
/* eslint-disable max-lines-per-function */
// @ts-check

import Swiper, { Pagination } from 'swiper';

const priceBoxBundle = () => {
    // pricebox-bundle
    const priceBundleSwipers = document.querySelectorAll('.priceBundleSwiper');
    const priceBundleBox = document.querySelector('.price-bundle-box');
    const pricecardBundles = document.querySelectorAll('.pricecard-bundle');

    // check if querySelector returns for each element
    if (!priceBundleSwipers || !priceBundleBox || !pricecardBundles) {
        return;
    }

    // TODO: BUG!!!! crashes side when on mobile
    if (window.innerWidth <= 1159) {
        const priceBundleBox = document.querySelector('.price-bundle-box');

        // Check if priceBundleBox exists before proceeding
        if (priceBundleBox) {
            priceBundleBox.classList.add('swiper-container');
            if (priceBundleBox.firstElementChild) {
                priceBundleBox.firstElementChild.classList.add('swiper-wrapper');
            }

            const pricecardBundles = document.querySelectorAll('.pricecard-bundle');
            pricecardBundles.forEach(bundle => {
                bundle.classList.add('swiper-slide');
            });
        }
    }

    Array.from(priceBundleSwipers).forEach((swiperElement, index) => {
        if (swiperElement instanceof HTMLElement && window.innerWidth <= 1159) {
            let swiper = new Swiper('.swiper-container', {
                modules: [Pagination],
                slidesPerView: 2,
                spaceBetween: 10,
                centeredSlides: true,
                initialSlide: 2,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    576: {
                        slidesPerView: 2,
                        initialSlide: 0,
                    },
                    992: {
                        slidesPerView: 'auto',
                        initialSlide: 0,
                    },
                },
            });
        }
    });
};

document.readyState === 'complete' ? priceBoxBundle() : window.addEventListener('load', priceBoxBundle);

// add timeout to prevent multiple resize events
let resizeTimer;
window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(priceBoxBundle, 250);
});
