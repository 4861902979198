/* eslint-disable no-unused-vars */
/* eslint-disable max-lines-per-function */
import Swiper, { Navigation, Pagination } from 'swiper';

const priceBoxLg = () => {
    // count all priceboxes
    const sliderPriceBoxLg = document.querySelectorAll('.pricebox-lg');

    // get first highlighted pricebox
    const sliderPriceCardHighlight = document.querySelector(
        '.pricecard.highlight:not(.swiper-slide-duplicate)'
    );
    // is needed to fade in pricebox after init, to avoid "flickering" while loading and focus highlighted pricecard
    const sliderPriceBoxLgWrapper = document.querySelector('.pricebox-lg__wrapper');

    // more than one pricebox? let's check and add a specific class
    let i = 0;
    for (i = 0; i < sliderPriceBoxLg.length; i++) {

        sliderPriceBoxLg[i].classList.add('pricebox-lg-' + i);

        // https://swiperjs.com
        let priceBox = new Swiper('.pricebox-lg-' + i, {
            modules: [Navigation, Pagination],
            spaceBetween: 15,
            slidesPerView: 'auto',
            slidesPerGroup: 1,
            loop: true,
            centeredSlides: false,
            grabCursor: false,
            navigation: {
                nextEl: '.swiper-button-next_pricebox-lg',
                prevEl: '.swiper-button-prev_pricebox-lg',
            },
            pagination: {
                el: '.swiper-pagination_pricebox-lg',
                dynamicBullets: true,
                clickable: true
            },
            breakpoints: {
                1200: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                    grabCursor: false,
                    allowTouchMove: true,
                    centeredSlides: false,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination_pricebox-lg',
                        dynamicBullets: true,
                    },
                },
            },
            on: {
                // on init
                init: function() {
                    // focus first highlight card up to max-width of 1199 pixels
                    if (window.matchMedia('(max-width: 1199px)').matches) {
                        // needs short timeout to ensure to get the first highlighted pricecard
                        setTimeout(() => {
                            // check if there's a highlighted pricecard
                            if (sliderPriceCardHighlight) {
                                // get first highlighted pricecard
                                const sliderPriceCardHighlightSlide =
                                    sliderPriceCardHighlight.dataset.highlight;
                                // we can only jump to a highlighted card after initialization
                                priceBox.slideTo(
                                    // set short "slide to highlighted card" time of 50ms
                                    sliderPriceCardHighlightSlide, 50, true
                                );
                            }
                        }, 50);
                    }
                    // fade in slider after highlighted pricecard is focused
                    setTimeout(() => {
                        // check if class exists
                        if (sliderPriceBoxLgWrapper) {
                            // fade in by adding class
                            sliderPriceBoxLgWrapper.classList.add('fadeIn');
                        }
                    }, 100);
                },
                // on resize
                resize: function() {
                    // check if class exists
                    if (sliderPriceBoxLgWrapper) {
                        // if fadeIn class exists remove it to make slider invisible on resize
                        if (sliderPriceBoxLgWrapper.classList.contains('fadeIn')) {
                            sliderPriceBoxLgWrapper.classList.remove('fadeIn');
                        }
                    }
                    // focus first highlight card up to max-width of 1199 pixels
                    if (window.matchMedia('(max-width: 1199px)').matches) {
                        // needs short timeout to ensure to get the first highlighted pricecard
                        setTimeout(() => {
                            // check if there's a highlighted pricecard
                            if (sliderPriceCardHighlight) {
                                // get first highlighted pricecard
                                const sliderPriceCardHighlightSlide =
                                    sliderPriceCardHighlight.dataset.highlight;
                                // we can only jump to a highlighted card after initialization
                                priceBox.slideTo(
                                    // set short "slide to highlighted card" time of 50ms
                                    sliderPriceCardHighlightSlide, 50, true
                                );
                            }
                        }, 50);
                    }
                    // fade in slider after highlighted pricecard is focused
                    setTimeout(() => {
                        // check if class exists
                        if (sliderPriceBoxLgWrapper) {
                            // fade in by adding class
                            sliderPriceBoxLgWrapper.classList.add('fadeIn');
                        }
                    }, 100);
                },
            },
        });
        let numberOfSlides = priceBox.slides;
        //get all slides that are not duplicates
        let numberOfSlidesNotDuplicate = [];
        for (let j = 0; j < numberOfSlides.length; j++) {
            if (!numberOfSlides[j].classList.contains('swiper-slide-duplicate')) {
                numberOfSlidesNotDuplicate.push(numberOfSlides[j]);
            }
        }
    }
};

document.readyState === 'complete' ?
    priceBoxLg() :
    window.addEventListener('load', priceBoxLg);
