/* eslint-disable max-lines-per-function */
// Setup the breakpoint variable
let breakpoint;

// Get the current breakpoint
const getBreakpoint = function () {
    return window.getComputedStyle(document.body, ':before').content.replace(/"/g, '');
};
// Set Attribute
const setDataAttribut = breakpoint => {
    document.body.setAttribute('data-device', breakpoint);
};

// Calculate breakpoint on page load
breakpoint = getBreakpoint();

// Set Attribute on page load
setDataAttribut(breakpoint);

// Recalculate breakpoint on resize
window.addEventListener('resize', function () {
    breakpoint = getBreakpoint();
    setDataAttribut(breakpoint);
}, false);

//event delegation
/*
document.body.addEventListener('click', function (e) {
    if (document.body.dataset.device === 'xs') {
        // target is a h2
        if (e.target.tagName === 'H2') {
            console.log('clicked h2 on XS');
        }
    }
});
*/
/* eslint-disable no-unused-vars */
// Add an event listener to the document body on click
document.body.addEventListener('click', handleClick);

function handleClick(e) {
    const isXsDevice = document.body.dataset.device === 'xs';
    const isJsFlipElement = e.target.closest('.js-flip');

    if (isXsDevice && isJsFlipElement) {
        const parentElement = e.target.closest('.flip-card2 .js-flip').closest('.flip-card2');
        const frontCard = parentElement.querySelector('.flip-card2__face--front');
        const backCard = parentElement.querySelector('.flip-card2__face--back');
        const frontCardHeight = frontCard.offsetHeight;
        const hasJsFlipParent = e.target.closest('.flip-card2 .js-flip');
        const isFlipped = parentElement.classList.contains('is--flipped');

        if (hasJsFlipParent && !isFlipped) {
            flipCard(backCard, frontCard, parentElement, frontCardHeight);
        } else if (isFlipped) {
            flipCard(backCard, frontCard, parentElement, frontCardHeight);
        }
    }
}

function flipCard(backCard, frontCard, parentElement, frontCardHeight) {
    setBackCardHeight(backCard, frontCardHeight);
    toggleCardClass(backCard, 'd-none');
    toggleCardClass(frontCard, 'd-none');
    toggleCardClass(parentElement, 'is--flipped');
}

function setBackCardHeight(backCard, frontCardHeight) {
    backCard.style.height = frontCardHeight + 'px';
}

function toggleCardClass(element, className) {
    element.classList.toggle(className);
}
