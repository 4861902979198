// @ts-check
// query selectors

// get the current VLZ
document.body.addEventListener('mousedown', (event) => {
    const { target } = event;
    const isPanelTitle = target.classList.contains('vlzswitch') || target.parentElement.classList.contains('vlzswitch');
    if (isPanelTitle) {
        const vlz12 = document.querySelector('.vlzswitch__scope[data-vlz="12"]:checked~* .vlzswitch label[for="vlz12"]');
        const currentVLZ = vlz12 !== null ? '1' : '12';
        const resultItems = document.querySelectorAll('.domain-list-result');
        const vlzSwitch = document.querySelector('.vlzswitch');
        if (!vlzSwitch) {
            return;
        }
        resultItems.forEach((resultItem) => {
            resultItem.setAttribute('data-active-vlz', currentVLZ);
        });
    }
});

// Highlight matches
function highlightMatches(text, searchValue) {
    const regex = new RegExp(`(${searchValue})`, 'gi');
    return text.replace(regex, '<mark class="domainaccordion--mark">$1</mark>');
}

// show or hide accordions
function handleAccordions(accordions, inputValue) {
    if(inputValue === ''){
        accordions.classList.remove('d-none');
    } else {
        accordions.classList.add('d-none');
    }
}

function displaySearchResult(item, searchValue, resultItem) {
    const highlightedDomainName = highlightMatches(item.getAttribute('data-domain-name'), searchValue);
    const newItem = item.cloneNode(true);
    newItem.innerHTML = newItem.innerHTML.replace(item.getAttribute('data-domain-name'), highlightedDomainName);
    resultItem.appendChild(newItem);
}

function removeDuplicateData(domainName, domainContractDuration, item, searchValue, resultItem, uniqueDomainNames) {
    const itemButton = item.querySelector('.btn');

    if (!uniqueDomainNames[domainContractDuration]) {
        uniqueDomainNames[domainContractDuration] = new Set();
    }

    if (!uniqueDomainNames[domainContractDuration].has(domainName)) {
        uniqueDomainNames[domainContractDuration].add(domainName);
        if (!itemButton) { // items without a button are not displayed
            return;
        }
        if(domainName.indexOf(searchValue) > -1){
            displaySearchResult(item, searchValue, resultItem);
        }
    }
}

function handleSearch(inputValue, domainAccordionElement, allItems) {
    const resultItem = domainAccordionElement.querySelector('.domain-list-result');
    const accordions = domainAccordionElement.querySelector('[data-accordion-box]');
    handleAccordions(accordions, inputValue);
    resultItem.innerHTML = '';
    if(inputValue === ''){
        return;
    }
    const searchValue = inputValue.includes('.') ? inputValue.toLowerCase() : '.' + inputValue.toLowerCase();
    const allItemsKeys = Object.keys(allItems);
    const uniqueDomainNames = {};
    allItemsKeys.forEach((vlz) => {
        const items = allItems[vlz];
        items.forEach((item) => {
            const domainName = item.getAttribute('data-domain-name').toLowerCase();
            const domainContractDuration = item.getAttribute('data-contract-duration').toLowerCase();
            removeDuplicateData(domainName, domainContractDuration, item, searchValue, resultItem, uniqueDomainNames);
        });
    });
}

function getAllElements(domainAccordionElement, vlzs) {
    const result = {};
    vlzs.forEach((vlz) => {
        const allItems = domainAccordionElement.querySelectorAll('[data-domain-infobox]');
        result[vlz] = allItems;
    });

    return result;
}

function updatePanelState(domainAccordionElement) {
    const openPanel = domainAccordionElement.querySelector('.accordion__box--active');
    if (openPanel) {
        const accordionId = openPanel.id.split('-')[1];
        const allDomainAccordionsWithSameId = domainAccordionElement.querySelectorAll(`#domainaccordion-${accordionId}`);

        allDomainAccordionsWithSameId.forEach((domainAccordion) => {
            if (!domainAccordion.classList.contains('accordion__box--active')) {
                const accordion__content = domainAccordion.querySelector('.accordion__content');
                const accordion__innercontent = domainAccordion.querySelector('.accordion__innercontent');
                domainAccordion.classList.add('accordion__box--active');
                let neededHeight;
                setTimeout(() => {
                    neededHeight = accordion__innercontent.offsetHeight + 24 + 'px';
                    accordion__content.style.height = neededHeight;
                }, 150);
            }
        });
    }
}

function syncAccordionPanelState(domainAccordionElement) {
    const vlzSwitch = domainAccordionElement.querySelector('.vlzswitch');

    if (vlzSwitch) {
        vlzSwitch.addEventListener('click', function () {
            updatePanelState(domainAccordionElement);
        });
    }
}

function addEventListenerToInputField(inputField, domainAccordionElement, allItems) {
    inputField.addEventListener('input', function (e) {
        const openPanel = domainAccordionElement.querySelector('.accordion__box--active');
        if (openPanel) {
            const accordion__content = openPanel.querySelector('.accordion__content');

            accordion__content.style.height = '0px';
            openPanel.classList.remove('accordion__box--active');
        }

        const inputValue = e.target.value;
        if (inputValue === '.') {
            return;
        }
        handleSearch(inputValue, domainAccordionElement, allItems);
    });
}

function giveUniqueIdToEachDomainaccordionElement() {
    const VLZ_VAUES = [1, 12];
    const domainAccordionElements = document.querySelectorAll('.domainaccordion_wrapper');

    let i = 0;
    domainAccordionElements.forEach((domainAccordionElement) => {
        const allItems = getAllElements(domainAccordionElement, VLZ_VAUES);
        const inputField = domainAccordionElement.querySelector('.domainaccordion__searchfield');

        i += 1;
        domainAccordionElement.setAttribute('data-domainaccordion-id', `domainaccordion__element-${i}`);
        if(inputField){
            addEventListenerToInputField(inputField, domainAccordionElement, allItems);
        }
        syncAccordionPanelState(domainAccordionElement);
    });
}

function setActiveVlzToResultItems() {
    const vlz12 = document.querySelector('.vlzswitch__scope[data-vlz="12"]:checked~* .vlzswitch label[for="vlz12"]');
    const currentVLZ = vlz12 !== null ? '12' : '1';
    const vlzSwitch = document.querySelector('.vlzswitch');
    if (!vlzSwitch) {
        return;
    }

    document.querySelectorAll('.domain-list-result').forEach((resultItem) => {
        resultItem.setAttribute('data-active-vlz', currentVLZ);
    });
}

document.addEventListener('DOMContentLoaded', function () {
    setActiveVlzToResultItems();
    giveUniqueIdToEachDomainaccordionElement();
});
