// @ts-check
/* eslint-disable max-lines-per-function */
// iIFE
(function() {
    const collElements = document.querySelectorAll('.seo_contentTable--collapsible');
    const stickyElement = document.querySelector('#sticky-element');
    // the ul.seo_liSelector li elements
    const liElements = document.querySelectorAll('.seo_liSelector li');

    //attach event listener to all li elements
    liElements.forEach(liElement => {
        liElement.addEventListener('click', () => {
            // check if sticky-element has the class 'sticky'
            const isSticky = stickyElement ? stickyElement.classList.contains('sticky') : false;
            // is data-device attribute on the body xs or sm
            const isMobile = document.body.getAttribute('data-device') === 'xs' || document.body.getAttribute('data-device') === 'sm';

            // prevaent the default behaviour of the link
            event.preventDefault();
            // get the href # value
            const href = liElement.querySelector('a').getAttribute('href');
            // find the corresponding element
            const element = document.querySelector(href);
            // compute th height of the sticky element
            const stickyElementHeight = stickyElement ? stickyElement.offsetHeight : 0;
            if (!isMobile) {
                if (isSticky){
                    //scroll to the element with the offset of the sticky element
                    window.scrollTo({
                        top: element.offsetTop - stickyElementHeight - 30,
                        behavior: 'smooth'
                    });                
                }else{
                    //scroll to the element
                    window.scrollTo({
                        top: element.offsetTop - stickyElementHeight - 30,
                        behavior: 'smooth'
                    });
                }            
            }else{
                if (isSticky){
                    window.scrollTo({
                        top: element.offsetTop - 90,
                        behavior: 'smooth'
                    });                
                }
                else{              
                    window.scrollTo({
                        top: element.offsetTop - stickyElementHeight - 90,
                        behavior: 'smooth'
                    });
                }
            }
        });
    });


    //check if stickyElement is set
    if (!stickyElement) {
        return;
    }
    
    // @ts-ignore
    const originalOffsetTop = stickyElement.offsetTop;

    /*
    Within the next lines, we controll the opening and closing of the content table
    */
    function toggleContentTable() {
        const content = this.nextElementSibling;
        
        if(!content) {
            return;
        }

        this.classList.toggle('icon-minus-alt');
        content.style.maxHeight = content.style.maxHeight ? null : `${content.scrollHeight}px`;
    }

    collElements.forEach(collElement => {
        collElement.addEventListener('click', toggleContentTable);
    });
    
    /*
    We check that the stickyElement is set, and if, the makeElementSticky() function will add the 'sticky' class to the element.
    If we scroll up again and reach the starting position of the content table element, the 'sticky' class will be removed.
    */
    function makeElementSticky() {    
        if (window.pageYOffset >= originalOffsetTop && stickyElement) {
            stickyElement.classList.add('sticky');
            createStickyElementContainer();
        } else if (stickyElement) {
            stickyElement.classList.remove('sticky');
            removeStickyElementContainer();
        }
    }
    
    /*
    We check if the viewport indicates the use of a mobile device.
    If so, we add some padding to between the lines to prevent misclicks by the users.
    The code will also close the content table, if open, when the user starts scrolling or clicks on a link. This prevents taking away screen real estate from the mobile device.
    */
    function toggleIconFont() {
        const collElements = document.getElementsByClassName('seo_contentTable--collapsible')[0];
        if (!collElements) {
            return;
        }
    
        if (collElements.classList.contains('icon-minus-alt')) {
            collElements.classList.remove('icon-minus-alt');
        }
    }
    
    function closeIndexList() {
        const content = document.getElementsByClassName('seo_contentTable__content')[0];
        if (!content) {
            return;
        }

        // @ts-ignore
        if (content.style.maxHeight) {
            // @ts-ignore
            content.style.maxHeight = null;
        }
    }
    
    function checkViewport() {
        const windowsWidth = window.innerWidth;
    
        if (windowsWidth <= 575) {
            closeIndexList();
            toggleIconFont();
        }
    }
    
    if (stickyElement) {
        window.addEventListener('scroll', () => {
            makeElementSticky();
            checkViewport();
        });
    }
    // function to create the a div container before the container with the id 'sticky-element' that has the same height as the sticky element
    function createStickyElementContainer() {

        // check if stickyElement is set
        if (!stickyElement || document.getElementById('sticky-element-container')) {
            return;
        }

        // create a div element
        const stickyElementContainer = document.createElement('div');
        // set the id of the div element
        stickyElementContainer.id = 'sticky-element-container';
        // set the height of the div element to the height of the sticky element
        stickyElementContainer.style.height = `${stickyElement.offsetHeight}px`;
        // insert the div element before the sticky element
        stickyElement.parentNode.insertBefore(stickyElementContainer, stickyElement);
    }
    function removeStickyElementContainer() {
        const stickyElementContainer = document.getElementById('sticky-element-container');
        if (!stickyElementContainer) {
            return;
        }
        stickyElementContainer.parentNode.removeChild(stickyElementContainer);
    }
})();
