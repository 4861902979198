//@ts-check
import Swiper, { Navigation, Pagination } from 'swiper';

/**
 * Initializes custom Swiper instances for different elements.
 * @param {HTMLElement} element - The element to initialize the Swiper on.
 * @param {Object} config - Configuration options for the Swiper instance.
 */
const initializeSwiper = (element, config) => {
    if (element instanceof HTMLElement) {
        return new Swiper(element, config);
    }
};

/**
 * Common configuration for Swiper instances.
 */
const commonSwiperConfig = {
    modules: [Navigation, Pagination],
    allowSlideNext: true,
    allowTouchMove: true,
    grabCursor: false,
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    lazy: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    speed: 300,
    on: {
        init: () => {
            // console.log(this);
        },
    },
};

/**
 * Initializes Swiper instances for different elements.
 */
// eslint-disable-next-line max-lines-per-function
const customSwiperInit = () => {
    const swipers = [
        {
            selector: '.chartSwiper',
            config: {
                ...commonSwiperConfig,
                effect: 'slide',
                slidesPerView: 1,
                spaceBetween: 10,
                breakpoints: {
                    320: { pagination: false },
                    600: { pagination: false },
                    768: {},
                },
            }
        },
        {
            selector: '.successSwiper',
            config: {
                ...commonSwiperConfig,
                loop: false,
                pagination: {
                    el: '.swiper-pagination',
                    dynamicBullets: true,
                    dynamicMainBullets: 7,
                },
                slidesPerGroup: 4,
                slidesPerView: 4,
                spaceBetween: 0,
                breakpoints: {
                    320: { pagination: false, slidesPerView: 1, slidesPerGroup: 1 },
                    600: { pagination: false, slidesPerView: 2, slidesPerGroup: 2 },
                    768: { pagination: { dynamicBullets: true, dynamicMainBullets: 7, clickable: true }, slidesPerView: 4, slidesPerGroup: 4 },
                },
            }
        },
        {
            selector: '.testimonialSwiper',
            config: {
                ...commonSwiperConfig,
                effect: 'slide',
                pagination: {
                    el: '.swiper-pagination',
                    dynamicBullets: true,
                },
            }
        },
        {
            selector: '.testimonialSwiper',
            config: {
                ...commonSwiperConfig,
                effect: 'slide',
                slidesPerView: 1,
                spaceBetween: 10,
                breakpoints: {
                    320: { pagination: { dynamicBullets: true, dynamicMainBullets: 7, clickable: true } },
                    600: { pagination: true },
                    768: {},
                },
            }
        },
        {
            selector: '.itemSwiper',
            config: {
                ...commonSwiperConfig,
                effect: 'slide',
                slidesPerGroup: 1,
                spaceBetween: 20,
                breakpoints: {
                    300: { slidesPerView: 1 },
                    500: { slidesPerView: 2, slidesPerGroup: 2 },
                    700: { slidesPerView: 3, slidesPerGroup: 3 },
                    930: { slidesPerView: 4, slidesPerGroup: 4 },
                    1024: { slidesPerView: 5, slidesPerGroup: 5 },
                },
            }
        },
        {
            selector: '.swiperTwoItems',
            config: {
                ...commonSwiperConfig,
                effect: 'slide',
                slidesPerGroup: 1,
                spaceBetween: 20,
                breakpoints: {
                    300: { slidesPerView: 1 },
                    500: { slidesPerView: 2, slidesPerGroup: 2 },
                    700: { slidesPerView: 2, slidesPerGroup: 2 },
                    930: { slidesPerView: 2, slidesPerGroup: 2 },
                    1024: { slidesPerView: 2, slidesPerGroup: 2 },
                },
            }
        },
        {
            selector: '.swiperTwoItems--spaced',
            config: {
                ...commonSwiperConfig,
                effect: 'slide',
                slidesPerGroup: 1,
                spaceBetween: 20,
                breakpoints: {
                    0: { slidesPerView: 1, slidesPerGroup: 1, centeredSlides: true },
                    576: { slidesPerView: 1, slidesPerGroup: 1, centeredSlides: true },
                    768: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 50 },
                    992: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 50 },
                }
            }
        },

        {
            selector: '.swiperThreeItems',
            config: {
                ...commonSwiperConfig,
                effect: 'slide',
                slidesPerGroup: 1,
                spaceBetween: 20,
                breakpoints: {
                    0:   { slidesPerView: 1, slidesPerGroup: 1, centeredSlides: true, spaceBetween: 10, initialSlide: 1 },
                    576: { slidesPerView: 2, slidesPerGroup: 2, centeredSlides: false, },
                    768: { slidesPerView: 2, slidesPerGroup: 2, },
                    992: { slidesPerView: 2, slidesPerGroup: 2 },
                    1024: { slidesPerView: 3, slidesPerGroup: 3 },
                },
            }
        },
        {
            selector: '.swiperThreeItems--pricecard',
            config: {
                ...commonSwiperConfig,
                effect: 'slide',
                slidesPerGroup: 1,
                spaceBetween: 20,
                pagination: {
                    el: ' .swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    0:   { slidesPerView: 1, slidesPerGroup: 1, pagination: { dynamicBullets: true, dynamicMainBullets: 7, clickable: true }, navigation: false, centeredSlides: true, spaceBetween: 10, initialSlide: 1 },
                    320: { slidesPerView: 1.1, slidesPerGroup: 1, centeredSlides: true, },
                    340: { slidesPerView: 1.3, slidesPerGroup: 1,  },
                    400: { slidesPerView: 1.4, slidesPerGroup: 1, centeredSlides: false, },
                    500: { slidesPerView: 1.6, slidesPerGroup: 1, centeredSlides: false, },
                    576: { slidesPerView: 1.6, slidesPerGroup: 1, centeredSlides: false, initialSlide: 1.6 },
                    620: { slidesPerView: 1.8, slidesPerGroup: 2, centeredSlides: false, initialSlide: 1.8 },
                    700: { slidesPerView: 2.0, slidesPerGroup: 2, centeredSlides: false, initialSlide: 2.0 },
                    768: { slidesPerView: 2.2, slidesPerGroup: 2, centeredSlides: false, initialSlide: 2.0 },
                    857: { slidesPerView: 2.6, slidesPerGroup: 2, centeredSlides: false, },
                    992: { slidesPerView: 3, slidesPerGroup: 3,  },
                    1024: { slidesPerView: 3, slidesPerGroup: 3 },
                    1200: { slidesPerView: 2.95, slidesPerGroup: 3, spaceBetween: 0,allowSlidePrev: false, allowSlideNext: false },
                    1440: { slidesPerView: 2.7, slidesPerGroup: 3 ,allowSlidePrev: false, allowSlideNext: false },
                },
                watchOverflow: false,

                navigation: {
                    nextEl: ' .swiper-button-next',
                    prevEl: ' .swiper-button-prev',
                },
            }
        },
        {
            selector: '.swiperFourItems',
            config: {
                ...commonSwiperConfig,
                effect: 'slide',
                slidesPerGroup: 1,
                spaceBetween: 20,
                pagination: {
                    el: ' .swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    0:   { slidesPerView: 1, slidesPerGroup: 1, pagination: { dynamicBullets: true, dynamicMainBullets: 7, clickable: true }, navigation: false, centeredSlides: true, spaceBetween: 10, initialSlide: 1 },
                    320: { slidesPerView: 1.1, slidesPerGroup: 1, centeredSlides: true, },
                    340: { slidesPerView: 1.3, slidesPerGroup: 1,  },
                    400: { slidesPerView: 1.4, slidesPerGroup: 1, centeredSlides: false, },
                    500: { slidesPerView: 1.6, slidesPerGroup: 1, centeredSlides: false, },
                    576: { slidesPerView: 1.6, slidesPerGroup: 1, centeredSlides: false, initialSlide: 1.6 },
                    620: { slidesPerView: 1.8, slidesPerGroup: 2, centeredSlides: false, initialSlide: 1.8 },
                    700: { slidesPerView: 2.0, slidesPerGroup: 2, centeredSlides: false, initialSlide: 2.0 },
                    768: { slidesPerView: 2.2, slidesPerGroup: 2, centeredSlides: false, initialSlide: 2.0 },
                    857: { slidesPerView: 2.6, slidesPerGroup: 2, centeredSlides: false, },
                    992: { slidesPerView: 3, slidesPerGroup: 3,  },
                    1024: { slidesPerView: 2.9, slidesPerGroup: 3 },
                    1200: { slidesPerView: 3, slidesPerGroup: 3, },
                    1300: { slidesPerView: 4, slidesPerGroup: 4, }
                },
                watchOverflow: false,

                navigation: {
                    nextEl: ' .swiper-button-next',
                    prevEl: ' .swiper-button-prev',
                },
            }
        },
        {
            selector: '.swiperFiveItems',
            config: {
                ...commonSwiperConfig,
                effect: 'slide',
                slidesPerGroup: 1,
                spaceBetween: 20,
                breakpoints: {
                    0:   { slidesPerView: 1, slidesPerGroup: 1, centeredSlides: true, spaceBetween: 10, initialSlide: 1 },
                    300: { slidesPerView: 1, slidesPerGroup: 1, },
                    576: { slidesPerView: 2, slidesPerGroup: 2, centeredSlides: false, },
                    768: { slidesPerView: 3, slidesPerGroup: 3, },
                    992: { slidesPerView: 4, slidesPerGroup: 4 },
                    1024: { slidesPerView: 5, slidesPerGroup: 5 }
                },
                watchOverflow: false,

                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            }
        },

        {
            selector: '.demo1',
            config: {
                ...commonSwiperConfig,
                effect: 'slide',
                spaceBetween: 20,
                pagination: {
                    el: '.container--demo1 .swiper-pagination',
                    clickable: true,
                },
                loop: false,
                breakpoints: {
                    0: { slidesPerView: 1.3, slidesPerGroup: 1, pagination: false, navigation: false, centeredSlides: true, spaceBetween: 10, initialSlide: 2 },
                    500: { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 10, initialSlide: 2 },
                    700: { slidesPerView: 2, slidesPerGroup: 2, initialSlide: 2 },
                    1024: { slidesPerView: 3, slidesPerGroup: 3 },
                    1200: { slidesPerView: 5, slidesPerGroup: 5 },
                },
                //if number of slides is less than slidesPerView, hide navigation

                watchOverflow: true,
                navigation: {
                    nextEl: '.container--demo1 .swiper-button-next',
                    prevEl: '.container--demo1 .swiper-button-prev',
                },
            }
        },
        {
            selector: '.swiper__filtered-table',
            config: {
                effect: 'slide',
                slidesPerView: 'auto',
                spaceBetween: 10,
                freeMode: true,
            }
        }
    ];

    swipers.forEach(({ selector, config }) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach(element => {
            const swiperElement = element; // Cast to HTMLElement
            if (swiperElement instanceof HTMLElement) {
                // @ts-ignore
                if (swiperElement.swiper) {
                    // @ts-ignore
                    swiperElement.swiper.destroy(true, true);
                }
                initializeSwiper(swiperElement, config);
            }
        });
    });
};

const initSwipers = () => {
    document.readyState === 'complete' ? customSwiperInit() : window.addEventListener('load', customSwiperInit);

    let resizeTimer;
    window.addEventListener('resize', () => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            customSwiperInit();
        }, 250);
    });
};

initSwipers();
