//get the selectbox value of #cars
const selectBoxes = document.querySelectorAll('[data-controls="show"]');
selectBoxes.forEach(function (selectBox) {
    const dataName = selectBox.getAttribute('name') || '';
    selectBox.addEventListener('change', function () {
        const selectedValue = getDataToShowValue(selectBox);
        syncDropdown(selectedValue, dataName);
        doTheAction();
    });
});
function doTheAction() {
    hideAllDataShow();
    showDataShowElement(getDataShowElements(getDataControlsShowElements()));
}
doTheAction();

//function to keep all the dropdowns in sync
function syncDropdown(selectedValue, dataName) {
    const switchers = document.querySelectorAll(`[data-controls="show"][name="${dataName}"]`);
    switchers.forEach(function (selectBox) {
        selectBox.value = selectedValue;
    });
}
//function to hide all data-show elements
function hideAllDataShow() {
    var dataShowElements = document.querySelectorAll('[data-show]');
    dataShowElements.forEach(function (dataShowElement) {
        //change class of the data-show element
        dataShowElement.classList.remove('d-block');
        dataShowElement.classList.add('d-none');
    });
}
//function to get the data-toshow value of the selected option
function getDataToShowValue(theSelectBox) {
    var selectedValue = theSelectBox.options[theSelectBox.selectedIndex].attributes['data-toshow'].value;
    return selectedValue;
}
//function to show the data-show element of the selected option
function showDataShowElement(dataShowElements) {
    dataShowElements.forEach(function (dataShowElement) {
        //change class of the data-show element
        dataShowElement.classList.remove('d-none');
        dataShowElement.classList.add('d-block');
    });
}
//function to get an array of all data-controls="show" elements
function getDataControlsShowElements() {
    const theSelectedoptions = [];
    const dataControlsShowElements = document.querySelectorAll('[data-controls="show"]');
    dataControlsShowElements.forEach(function (dataControlsShowElement) {
        theSelectedoptions.push(getDataToShowValue(dataControlsShowElement));
    });
    return theSelectedoptions;
}
//function to get all elements that have a data-show attribute with the item in the array
function getDataShowElements(theArray) {
    var dataShowElements = document.querySelectorAll('[data-show]');
    // Remove all elements from dataShowElements that are not in theArray
    dataShowElements = Array.from(dataShowElements).filter(function (dataShowElement) {
        return theArray.includes(dataShowElement.getAttribute('data-show'));
    });
    return dataShowElements;
}
