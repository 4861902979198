//@ts-check
import SELECT from '../dom/selector-engine';

const DEBUG = false; // DEBUG-Mode: damit flyout stehen bleibt

(function() {
    const nav = SELECT.find('.nav-main')[0];
    if (DEBUG) nav.classList.add('nav__item--debug');
    const navItems = SELECT.find('.nav__item');
    const navItemsLinks = SELECT.find('.nav__item--links a');
    const burgerIcon = SELECT.find('.burgericon');
    const currentPath = window.location.href; // aktuellen Pfad (URL) global speichern

    const menuSetActive = element => {
        menuRemoveActive();
        element.classList.add('nav__item--active');
    };

    const menuRemoveActive = () => {
        navItems.forEach(element => {
            if (!DEBUG) element.classList.remove('nav__item--active'); // kein hide im DEBUG mode
        });
    };

    const menuSetActiveDelay = (element) => {
        const SECOND = 1000;
        const delay = SECOND / 4;
        const callback = () => {
            if (!hasHover(element.target)) {
                return false;
            }
            menuSetActive(element.target);
        };
        window.setTimeout(callback, delay);
    };

    const toggleActive = (element) => {
        if (element.currentTarget.classList.contains('nav__item--active')) {
            menuRemoveActive();
        } else {
            menuRemoveActive();
            element.currentTarget.classList.add('nav__item--active');
        }
    };

    const hasHover = element => {
        return element.classList.contains('nav__item--hover');
    };

    // Speichere bei Klick die übergeordnete nav__item ID im sessionStorage
    const menuItems = document.querySelectorAll('.nav__item--link');
    menuItems.forEach(item => {
        item.addEventListener('click', function(event) {
            // Verhindern, dass der Link sofort weitergeleitet wird
            event.preventDefault();
            // Die ID des übergeordneten Elements speichern
            const parent = this.closest('.nav__item--container');
            const prevelement = parent.previousElementSibling;
            const menuId = prevelement ? prevelement.id : null;
            if (menuId) {
                sessionStorage.setItem('parentMenuItemId', menuId);
            }
            // Weiterleitung nach dem Speichern
            window.location.href = this.href;
        });
    });

    // Hauptfunktion zum Hervorheben des aktuellen Menüelements
    const highlightCurrentMenuItem = () => {
        // Wenn die aktuelle Seite die Startseite ist, keine Hervorhebung
        if (window.location.pathname === '/' || window.location.pathname === '/index.html') {
            return;
        }
        const menuItemId = sessionStorage.getItem('parentMenuItemId');
        if (menuItemId) {
            // Wenn menuItemId im sessionStorage, hebe dieses Element hervor
            highlightMenuItemById(menuItemId);
        } else {
            // Wenn keine menuItemId gespeichert ist, prüfe die aktuelle URL und hebe entsprechend hervor
            highlightMenuItemByPath();
        }
    };

    // Funktion zum Hervorheben des aktuellen Sub-Menüelements
    const highlightCurrentSubMenuItem = () => {
        // Alle Links im nachfolgenden Container mit der Klasse 'nav__item--links' finden
        const links = document.querySelectorAll('.nav__item--links a');
        links.forEach(link => {
            // Überprüfen, ob der Link existiert und ob seine URL mit der aktuellen URL übereinstimmt
            if (link.href === currentPath) {
                // Die Klasse 'nav__item--sub-select' zum Link hinzufügen
                link.classList.add('nav__item--sub-select');
            }
        });
    };

    // Hilfsfunktion zum Hervorheben eines Menüelements anhand seiner ID
    const highlightMenuItemById = (menuItemId) => {
        const element = document.getElementById(menuItemId);
        if (element) {
            // Füge die Klasse 'nav__item--select' zum Element hinzu, um es hervorzuheben
            element.classList.add('nav__item--select');
        }
    };

    // Hilfsfunktion zum Finden des Links mit einem bestimmten Attribut
    const findLinkWithDefaultMenuEntry = (links, path) => {
        // Finden des Links mit 'data-default-menu-entry' Attribut und übereinstimmender URL
        const link = [...links].find(link =>
            link.getAttribute('href') === path && link.getAttribute('data-default-menu-entry')
        );

        return link;
    };

    // Hilfsfunktion zum Hervorheben eines Menüelements anhand des data-default-menu-entry
    // Wird benötigt wenn initial keine menuItemId im sessionStorage gespeichert ist
    const highlightMenuItemByPath = () => {
        const currentPath = window.location.pathname;

        // Übergabe von navItemsLinks als Parameter
        const linkWithDefaultMenuEntry = findLinkWithDefaultMenuEntry(navItemsLinks, currentPath);

        if (linkWithDefaultMenuEntry) {
            const defaultMenuEntry = linkWithDefaultMenuEntry.getAttribute('data-default-menu-entry');
            if (defaultMenuEntry) {
                highlightMenuItemById(defaultMenuEntry);
            }
        } else {
            highlightRegularMenuItem();
        }
    };

    // Hilfsfunktion zum Hervorheben eines regulären Menüelements
    const highlightRegularMenuItem = () => {
        navItemsLinks.forEach(link => {
            if (link.getAttribute('href') === currentPath) {
                const parent = link.closest('.nav__item--container');
                const prevelement = parent.previousElementSibling;
                if (prevelement) {
                    // Füge die Klasse 'nav__item--select' zum übergeordneten Element hinzu, um es hervorzuheben
                    prevelement.classList.add('nav__item--select');
                }
            }
        });
    };

    // nav-main__more element
    const navMainMore = document.querySelector('.nav-main__more');
    if (navMainMore) {
        navMainMore.addEventListener('mouseenter', () => {
            navMainMore.classList.add('nav-main__more--active');
        });
        navMainMore.addEventListener('mouseleave', () => {
            navMainMore.classList.remove('nav-main__more--active');
        });
    }

    navItems.forEach(element => {
        element.addEventListener('mouseenter', () => {
            element.classList.add('nav__item--hover');
        });
        element.addEventListener('mouseleave', () => {
            navItems.forEach(element => {
                element.classList.remove('nav__item--hover');
            });
        });
    });

    navItems.forEach(element => {
        if (window.matchMedia('(min-width: 992px)').matches) {
            element.addEventListener('mouseenter', menuSetActiveDelay);
        } else {
            element.addEventListener('click', toggleActive);
        }
    });

    burgerIcon.forEach(element => {
        element.addEventListener('click', () => {
            nav.classList.toggle('nav-mobile--active');
            const navActivePage = document.querySelector('.nav__item--select');
            if (navActivePage) {
                navActivePage.classList.add('nav__item--active');
            }
        });
    });

    if (nav) {
        nav.classList.remove('nav-mobile--active');
        nav.addEventListener('mouseleave', menuRemoveActive);
    }

    // RESIZE
    window.addEventListener('resize', () => {
        nav.classList.remove('nav-mobile--active');
    });

    // Initial Highlighting aufrufen
    highlightCurrentMenuItem();
    highlightCurrentSubMenuItem();

})();
