/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */

import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';

const initStepSlider = (gallery, content, index) => {
    const contentSlider = new Swiper(content, {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 1,
        watchSlidesProgress: true,
        slideToClickedSlide: false,
        allowTouchMove: false,
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 40,
            }
        },
    });
    const gallerySlider = new Swiper(gallery, {
        modules: [Navigation, Pagination, Thumbs],
        loop: true,
        spaceBetween: 10,
        slidesPerView: 1.25,
        centeredSlides: true,
        navigation: {
            nextEl: '.three_steps__button--next',
            prevEl: '.three_steps__button--prev',
        },
        pagination: {
            el: '.three_steps__pagination',
            clickable: true,
        },
        thumbs: {
            swiper: contentSlider
        },
        breakpoints: {
            768: {
                slidesPerView: 1,
            }
        },
    });
};

const initStepSliders = () => {
    const galleries = document.querySelectorAll('.three_steps__gallery');
    const contents = document.querySelectorAll('.three_steps__content');
    galleries.forEach((gallery, index) => {
        const content = contents[index];
        gallery.classList.add(`three_steps__gallery-${index}`);
        content.classList.add(`three_steps__content-${index}`);
        initStepSlider(gallery, content, index);
    });
};

const handleLoad = () => {
    initStepSliders();
};

if (document.readyState === 'complete') {
    handleLoad();
} else {
    window.addEventListener('load', handleLoad);
}
